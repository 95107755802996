
.modal-video-close-btn {
  /* display: none !important; */
  color: #000;
}

.modal-video-inner{
  width: 100% !important;
  padding: 0px 0px !important;
}

.modal-video{
  background-color: transparent !important;
}


.container-custom {
  width: 100%;
  max-width: 100%;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

.container-custom .nav-padding {
  padding-left: 40px !important;
}

.gd_testimonial.gd_style_1 {
  background: #ffffff;
}
.gd_client_info {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 25px;
}

.gd_client_name {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 2px;
}

.gd_client_ratings i {
  color: #000;
}
.gd_client_review {
  margin-bottom: 20px;
}

.gd_client_img {
  flex: none;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  overflow: hidden;
}
.gd_rating {
  transform: scale(0.9);
  margin-left: -7px;
}

.gd_rating img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.card-inners {
  box-sizing: border-box;
  margin: 10px;
  background: #ffffff;
  /* box-shadow: 0px 0px 20px 10px rgba(216, 216, 216, 0.25); */
  min-width: 60%;
  border-radius: 15px;
  padding: 40px;
}

.square {
  height: 15px;
  width: 15px;
  border: 2px solid black;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dots {
  height: 5px;
  width: 5px;
  border-radius: 20px solid #000;
  background-color: #000;
}

.dots-w {
  height: 5px;
  width: 5px;
  border-radius: 20px solid transparent;
  background-color: transparent;
}

/* video */

.model-video {
  z-index: 2000 !important;
}

.no-scroll {
  overflow: hidden;
}

.video-container .video {
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.video-container .video-img {
  object-fit: cover;
  position: absolute;
  height: 576px;
  width: 100%;
  top: 0;
  left: 0;
}

.video-container {
  height: 576px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.video-container-overlay {
  content: "";
  display: block;
  height: 576px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2;
  /* display: none; */
}

.callout {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 576px;
  text-align: center;
  position: relative;
  z-index: 10;
  width: 70%;
  margin: auto;
  width: 90%;
  padding: 40px;
}





@keyframes modal-video {
	 from {
		 opacity: 0;
	}
	 to {
		 opacity: 1;
	}
}
 @keyframes modal-video-inner {
	 from {
		 transform: translate(0, 100px);
	}
	 to {
		 transform: translate(0, 0);
	}
}



 /* .mockup{
  width: 50%;
 } */


 .modal-video {
	 position: fixed;
	 top: 0;
	 left: 0;
	 width: 100%;
	 height: 100%;
	 background-color: rgba(0, 0, 0, .5);
	 z-index: 1000000;
	 cursor: pointer;
	 opacity: 1;
	 animation-timing-function: ease-out;
	 animation-duration: 0.3s;
	 animation-name: modal-video;
	 -webkit-transition: opacity 0.3s ease-out;
	 -moz-transition: opacity 0.3s ease-out;
	 -ms-transition: opacity 0.3s ease-out;
	 -o-transition: opacity 0.3s ease-out;
	 transition: opacity 0.3s ease-out;
}
 .modal-video-effect-exit {
	 opacity: 0;
}
 .modal-video-effect-exit .modal-video-movie-wrap {
	 -webkit-transform: translate(0, 100px);
	 -moz-transform: translate(0, 100px);
	 -ms-transform: translate(0, 100px);
	 -o-transform: translate(0, 100px);
	 transform: translate(0, 100px);
}
 .modal-video-body {
	 max-width: 100% !important;
	 width: 100%;
	 height: 100%;
	 margin: 0 auto;
	 padding: 0 10px;
	 display: flex;
	 justify-content: center;
	 box-sizing: border-box;
   background-color: #00000060;
   width: 100%;
}
 .modal-video-inner {
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 width: 80% !important;
	 height: 100%;
}
 @media (orientation: landscape) {
	 .modal-video-inner {
		 padding: 10px 60px;
		 box-sizing: border-box;
	}
}
 .modal-video-movie-wrap {
	 width: 100%;
	 height: 0;
	 position: relative;
	 padding-bottom: 56.25%;
	 background-color: #333;
	 animation-timing-function: ease-out;
	 animation-duration: 0.3s;
	 animation-name: modal-video-inner;
	 -webkit-transform: translate(0, 0);
	 -moz-transform: translate(0, 0);
	 -ms-transform: translate(0, 0);
	 -o-transform: translate(0, 0);
	 transform: translate(0, 0);
	 -webkit-transition: -webkit-transform 0.3s ease-out;
	 -moz-transition: -moz-transform 0.3s ease-out;
	 -ms-transition: -ms-transform 0.3s ease-out;
	 -o-transition: -o-transform 0.3s ease-out;
	 transition: transform 0.3s ease-out;
}
 .modal-video-movie-wrap iframe {
	 position: absolute;
	 top: 0;
	 left: 0;
	 width: 100%;
	 height: 100%;
}
 .modal-video-close-btn {
	 position: absolute;
	 z-index: 2;
	 top: -45px;
	 right: 40px; 
	 display: inline-block;
	 width: 35px;
	 height: 35px;
	 overflow: hidden;
	 border: none;
	 background: transparent;
}
 @media (orientation: landscape) {
	 .modal-video-close-btn {
		 top: 0;
		 right: -45px;
	}
}
 .modal-video-close-btn:before {
	 transform: rotate(45deg);
}
 .modal-video-close-btn:after {
	 transform: rotate(-45deg);
}
 .modal-video-close-btn:before, .modal-video-close-btn:after {
	 content: '';
	 position: absolute;
	 height: 2px;
	 width: 100%;
	 top: 50%;
	 left: 0;
	 margin-top: -1px;
	 background: #fff;
	 border-radius: 5px;
	 margin-top: -6px;
}
 











/* anchor heading */

.active-link {
  color: #ffffff !important;
  background-color: rgba(0, 0, 0, 0);
}

.react-modal-video-overlay {
  z-index: 9999 !important; /* or any other high value */
}

/* Ensure the modal itself has a high z-index */
.react-modal-video {
  z-index: 10000 !important; /* or any other high value */
}

/* Chrome, Safari, Edge, Opera */
.video-field input::-webkit-outer-spin-button,
.video-field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.video-field input[type="number"] {
  -moz-appearance: textfield;
}

@media (min-width: 576px) {
  .container-custom {
    max-width: 540px;
  }
  
}
@media (min-width: 768px) {
  .container-custom {
    max-width: 720px;
  }
}




@media (min-width: 992px) {
  .container-custom {
    max-width: 960px;
  }
  .container-custom .nav-padding {
    padding-left: 80px !important;
  }
}
@media (min-width: 1200px) {
  .container-custom {
    max-width: 1140px;
  }
}

@media (max-width: 1200px) {
  .active-link {
    color: #ffffff !important;
    background-color: #452c6350;
  }

  .active-link:hover {
    background-color: #452c6370;
  }
}



@media (min-width: 1400px) {
  .container-custom {
    max-width: 1320px;
  }

  .container-custom .nav-padding {
    padding-left: 60px !important;
  }
}


@media (min-width: 1260px) and (max-width: 1400px) {
  .mockup{
    width: 50%;
  }

  .guruji{
    width: 25%;
  }
}





