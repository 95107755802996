/*--------------------------------------------------------------
  6. Slider
----------------------------------------------------------------*/
.slick-list {
  overflow: visible;
}

.cs_slider {
  @media (max-width: 1199px) {
    padding: 0 15px;
  }
}

.slick-slider {
  margin: 0 -15px;
}

.slick-slide {
  margin-right: 15px;
  margin-left: 15px;
}

.slick-dots {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 0;
  margin-top: 40px;
  button {
    padding: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  li {
    overflow: hidden;
    border: none;
    width: 12px;
    height: 12px;
    border: 2px solid $primary;
    border-radius: 2px;
    background-color: $white;
    font-size: 0px;
    position: relative;
    padding: 0;
    cursor: pointer;

    &::after {
      content: '';
      width: 4px;
      height: 4px;
      background-color: $primary;
      border-radius: 1px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }
  }

  .slick-active {
    &::after {
      opacity: 1;
    }
  }
}
