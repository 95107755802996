/*--------------------------------------------------------------
  11. IconBox
----------------------------------------------------------------*/

.cs_iconbox.cs_style_1 {
  padding: 45px 30px 40px;
  transition: all 0.4s ease-in-out;
  background-color: $white;
  box-shadow: 0px 0px 20px 10px rgba(216, 216, 216, 0.25);
  border-radius: 15px;
  position: relative;
  z-index: 1;

  @media (max-width: 1400px) {
    padding: 45px 25px 40px;
  }

  .cs_iconbox_icon {
    height: 56px;
    margin-bottom: 25px;
  }

  .cs_iconbox_title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 15px;

    @media (max-width: 1399px) {
      font-size: 20px;
    }
  }

  .cs_iconbox_subtitle {
    margin-bottom: 25px;
  }

  .cs_text_btn {
    color: $accent;
    font-weight: 600;
  }

  &:hover {
    transform: scale(1.01) translateY(-7px);
  }
}

.cs_iconbox.cs_style_2 {
  .cs_iconbox_title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 7px;

    @media (max-width: 1399px) {
      font-size: 20px;
    }
  }

  .cs_number_box {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: 600;
  }

  .cs_iconbox_subtitle {
    margin-bottom: 0;
  }
}