/*--------------------------------------------------------------
  14. Partners
----------------------------------------------------------------*/
.cs_partners {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px 30px;
  @media (max-width: 450px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px 30px;
  }
  .cs_partner {
    img {
      filter: grayscale(100%);
      transition: all 0.4s ease;
      &:hover {
        filter: grayscale(0%);
      }
    }
  }
}
