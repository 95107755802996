/*--------------------------------------------------------------
>> TABLE OF CONTENTS:
----------------------------------------------------------------
1. Typography
2. Preloader
3. Spacing
4. General
5. Header
6. Slider
7. CTA
8. Footer
9. Comments
10. Hero
11. IconBox
12. Pricing
13. Testimonial
14. Partners
15. Accordian
16. Post
17. Contact
18. Blog Details
19. Modal
20. Login
--------------------------------------------------------------*/
/*--------------------------------------------------------------
1. Typography
----------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

/* hindi font */
@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=Khand:wght@300;400;500;600;700&family=Martel:wght@200;300;400;600;700;800;900&display=swap');

:root {
  --custom-color: #452c63;
}


body,
html {
  font-size: 16px;
  font-weight: 400;
  /* line-height: 1.6em; */
  overflow-x: hidden;
  font-family: "Inter", sans-serif;
  color: #6b6b6b;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  color: #0d0c17;
  padding: 0;
  margin: 0 0 20px 0;
  font-weight: 700;
  line-height: 1.3em;
  font-family: "Inter", sans-serif,"Martel";
}

h1 {
  font-size: 56px;
}

/* h2 {
  font-size: 42px;
} */

h3 {
  font-size: 30px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}



/* p {
  margin-bottom: 15px;
} */

ul {
  margin: 0 0 25px 0;
  padding-left: 20px;
  list-style: square outside none;
}

ol {
  padding-left: 20px;
  margin-bottom: 25px;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  margin: 0 15px;
  font-style: italic;
  font-size: 20px;
  line-height: 1.6em;
  margin: 0;
}

address {
  margin: 0 0 15px;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
}



button {
  color: inherit;
  transition: all 0.3s ease;
}




.cs_backtotop {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--custom-color);
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: fixed;
  right: 40px;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 4;
}
.cs_backtotop.active {
  visibility: visible;
  opacity: 1;
  bottom: 50px;
}

/* table {
  width: 100%;
  margin-bottom: 25px;
}
table th {
  font-weight: 600;
  color: #6b6b6b;
}
table td,
table th {
  border-top: 1px solid #a3a2a3;
  padding: 11px 10px;
} */


dl {
  margin-bottom: 25px;
}
dl dt {
  font-weight: 600;
}

b,
strong {
  font-weight: bold;
}

pre {
  color: #6b6b6b;
  border: 1px solid #a3a2a3;
  font-size: 18px;
  padding: 25px;
  border-radius: 5px;
}

kbd {
  font-size: 100%;
  background-color: #6b6b6b;
  border-radius: 5px;
}

input,
textarea {
  color: #0d0c17;
}

/*--------------------------------------------------------------
  2. Preloader
----------------------------------------------------------------*/
.cs_perloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  background: #fff;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cs_perloader_dots_wrap {
  display: block;
  margin: 0px auto;
  width: 97px;
  transform: scale(2);
}
.cs_perloader_dots_wrap .cs_perloader_dots i {
  width: 19px;
  height: 19px;
  display: inline-block;
  border-radius: 50%;
  background: var(--custom-color);
}
.cs_perloader_dots_wrap .cs_perloader_dots i:first-child {
  opacity: 0;
  animation: cs_perloader_dots-ani2 0.58s linear infinite;
  transform: translate(-19px);
}
.cs_perloader_dots_wrap .cs_perloader_dots i:nth-child(2), .cs_perloader_dots_wrap .cs_perloader_dots i:nth-child(3) {
  animation: cs_perloader_dots-ani3 0.58s linear infinite;
}
.cs_perloader_dots_wrap .cs_perloader_dots i:last-child {
  animation: cs_perloader_dots-ani1 0.58s linear infinite;
}

@keyframes cs_perloader_dots-ani1 {
  100% {
    transform: translate(39px);
    opacity: 0;
  }
}
@keyframes cs_perloader_dots-ani2 {
  100% {
    transform: translate(19px);
    opacity: 1;
  }
}
@keyframes cs_perloader_dots-ani3 {
  100% {
    transform: translate(19px);
  }
}
.cs_perloader_text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  color: #6b6b6b;
  letter-spacing: 3px;
}

/*--------------------------------------------------------------
  3. Spacing
----------------------------------------------------------------*/
.cs_height_1 {
  height: 1px;
}

.cs_height_2 {
  height: 2px;
}

.cs_height_3 {
  height: 3px;
}

.cs_height_4 {
  height: 4px;
}

.cs_height_5 {
  height: 5px;
}

.cs_height_6 {
  height: 6px;
}

.cs_height_7 {
  height: 7px;
}

.cs_height_8 {
  height: 8px;
}

.cs_height_9 {
  height: 9px;
}

.cs_height_10 {
  height: 10px;
}

.cs_height_11 {
  height: 11px;
}

.cs_height_12 {
  height: 12px;
}

.cs_height_13 {
  height: 13px;
}

.cs_height_14 {
  height: 14px;
}

.cs_height_15 {
  height: 15px;
}

.cs_height_16 {
  height: 16px;
}

.cs_height_17 {
  height: 17px;
}

.cs_height_18 {
  height: 18px;
}

.cs_height_19 {
  height: 19px;
}

.cs_height_20 {
  height: 20px;
}

.cs_height_21 {
  height: 21px;
}

.cs_height_22 {
  height: 22px;
}

.cs_height_23 {
  height: 23px;
}

.cs_height_24 {
  height: 24px;
}

.cs_height_25 {
  height: 25px;
}

.cs_height_26 {
  height: 26px;
}

.cs_height_27 {
  height: 27px;
}

.cs_height_28 {
  height: 28px;
}

.cs_height_29 {
  height: 29px;
}

.cs_height_30 {
  height: 30px;
}

.cs_height_31 {
  height: 31px;
}

.cs_height_32 {
  height: 32px;
}

.cs_height_33 {
  height: 33px;
}

.cs_height_34 {
  height: 34px;
}

.cs_height_35 {
  height: 35px;
}

.cs_height_36 {
  height: 36px;
}

.cs_height_37 {
  height: 37px;
}

.cs_height_38 {
  height: 38px;
}

.cs_height_39 {
  height: 39px;
}

.cs_height_40 {
  height: 40px;
}

.cs_height_41 {
  height: 41px;
}

.cs_height_42 {
  height: 42px;
}

.cs_height_43 {
  height: 43px;
}

.cs_height_44 {
  height: 44px;
}

.cs_height_45 {
  height: 45px;
}

.cs_height_46 {
  height: 46px;
}

.cs_height_47 {
  height: 47px;
}

.cs_height_48 {
  height: 48px;
}

.cs_height_49 {
  height: 49px;
}

.cs_height_50 {
  height: 50px;
}

.cs_height_51 {
  height: 51px;
}

.cs_height_52 {
  height: 52px;
}

.cs_height_53 {
  height: 53px;
}

.cs_height_54 {
  height: 54px;
}

.cs_height_55 {
  height: 55px;
}

.cs_height_56 {
  height: 56px;
}

.cs_height_57 {
  height: 57px;
}

.cs_height_58 {
  height: 58px;
}

.cs_height_59 {
  height: 59px;
}

.cs_height_60 {
  height: 60px;
}

.cs_height_61 {
  height: 61px;
}

.cs_height_62 {
  height: 62px;
}

.cs_height_63 {
  height: 63px;
}

.cs_height_64 {
  height: 64px;
}

.cs_height_65 {
  height: 65px;
}

.cs_height_66 {
  height: 66px;
}

.cs_height_67 {
  height: 67px;
}

.cs_height_68 {
  height: 68px;
}

.cs_height_69 {
  height: 69px;
}

.cs_height_70 {
  height: 70px;
}

.cs_height_71 {
  height: 71px;
}

.cs_height_72 {
  height: 72px;
}

.cs_height_73 {
  height: 73px;
}

.cs_height_74 {
  height: 74px;
}

.cs_height_75 {
  height: 75px;
}

.cs_height_76 {
  height: 76px;
}

.cs_height_77 {
  height: 77px;
}

.cs_height_78 {
  height: 78px;
}

.cs_height_79 {
  height: 79px;
}

.cs_height_80 {
  height: 80px;
}

.cs_height_81 {
  height: 81px;
}

.cs_height_82 {
  height: 82px;
}

.cs_height_83 {
  height: 83px;
}

.cs_height_84 {
  height: 84px;
}

.cs_height_85 {
  height: 85px;
}

.cs_height_86 {
  height: 86px;
}

.cs_height_87 {
  height: 87px;
}

.cs_height_88 {
  height: 88px;
}

.cs_height_89 {
  height: 89px;
}

.cs_height_90 {
  height: 90px;
}

.cs_height_91 {
  height: 91px;
}

.cs_height_92 {
  height: 92px;
}

.cs_height_93 {
  height: 93px;
}

.cs_height_94 {
  height: 94px;
}

.cs_height_95 {
  height: 95px;
}

.cs_height_96 {
  height: 96px;
}

.cs_height_97 {
  height: 97px;
}

.cs_height_98 {
  height: 98px;
}

.cs_height_99 {
  height: 99px;
}

.cs_height_100 {
  height: 100px;
}

.cs_height_101 {
  height: 101px;
}

.cs_height_102 {
  height: 102px;
}

.cs_height_103 {
  height: 103px;
}

.cs_height_104 {
  height: 104px;
}

.cs_height_105 {
  height: 105px;
}

.cs_height_106 {
  height: 106px;
}

.cs_height_107 {
  height: 107px;
}

.cs_height_108 {
  height: 108px;
}

.cs_height_109 {
  height: 109px;
}

.cs_height_110 {
  height: 110px;
}

.cs_height_111 {
  height: 111px;
}

.cs_height_112 {
  height: 112px;
}

.cs_height_113 {
  height: 113px;
}

.cs_height_114 {
  height: 114px;
}

.cs_height_115 {
  height: 115px;
}

.cs_height_116 {
  height: 116px;
}

.cs_height_117 {
  height: 117px;
}

.cs_height_118 {
  height: 118px;
}

.cs_height_119 {
  height: 119px;
}

.cs_height_120 {
  height: 120px;
}

.cs_height_121 {
  height: 121px;
}

.cs_height_122 {
  height: 122px;
}

.cs_height_123 {
  height: 123px;
}

.cs_height_124 {
  height: 124px;
}

.cs_height_125 {
  height: 125px;
}

.cs_height_126 {
  height: 126px;
}

.cs_height_127 {
  height: 127px;
}

.cs_height_128 {
  height: 128px;
}

.cs_height_129 {
  height: 129px;
}

.cs_height_130 {
  height: 130px;
}

.cs_height_131 {
  height: 131px;
}

.cs_height_132 {
  height: 132px;
}

.cs_height_133 {
  height: 133px;
}

.cs_height_134 {
  height: 134px;
}

.cs_height_135 {
  height: 135px;
}

.cs_height_136 {
  height: 136px;
}

.cs_height_137 {
  height: 137px;
}

.cs_height_138 {
  height: 138px;
}

.cs_height_139 {
  height: 139px;
}

.cs_height_140 {
  height: 140px;
}

.cs_height_141 {
  height: 141px;
}

.cs_height_142 {
  height: 142px;
}

.cs_height_143 {
  height: 143px;
}

.cs_height_144 {
  height: 144px;
}

.cs_height_145 {
  height: 145px;
}

.cs_height_146 {
  height: 146px;
}

.cs_height_147 {
  height: 147px;
}

.cs_height_148 {
  height: 148px;
}

.cs_height_149 {
  height: 149px;
}

.cs_height_150 {
  height: 150px;
}

.cs_height_151 {
  height: 151px;
}

.cs_height_152 {
  height: 152px;
}

.cs_height_153 {
  height: 153px;
}

.cs_height_154 {
  height: 154px;
}

.cs_height_155 {
  height: 155px;
}

.cs_height_156 {
  height: 156px;
}

.cs_height_157 {
  height: 157px;
}

.cs_height_158 {
  height: 158px;
}

.cs_height_159 {
  height: 159px;
}

.cs_height_160 {
  height: 160px;
}

@media screen and (max-width: 991px) {
  .cs_height_lg_1 {
    height: 1px;
  }
  .cs_height_lg_2 {
    height: 2px;
  }
  .cs_height_lg_3 {
    height: 3px;
  }
  .cs_height_lg_4 {
    height: 4px;
  }
  .cs_height_lg_5 {
    height: 5px;
  }
  .cs_height_lg_6 {
    height: 6px;
  }
  .cs_height_lg_7 {
    height: 7px;
  }
  .cs_height_lg_8 {
    height: 8px;
  }
  .cs_height_lg_9 {
    height: 9px;
  }
  .cs_height_lg_10 {
    height: 10px;
  }
  .cs_height_lg_11 {
    height: 11px;
  }
  .cs_height_lg_12 {
    height: 12px;
  }
  .cs_height_lg_13 {
    height: 13px;
  }
  .cs_height_lg_14 {
    height: 14px;
  }
  .cs_height_lg_15 {
    height: 15px;
  }
  .cs_height_lg_16 {
    height: 16px;
  }
  .cs_height_lg_17 {
    height: 17px;
  }
  .cs_height_lg_18 {
    height: 18px;
  }
  .cs_height_lg_19 {
    height: 19px;
  }
  .cs_height_lg_20 {
    height: 20px;
  }
  .cs_height_lg_21 {
    height: 21px;
  }
  .cs_height_lg_22 {
    height: 22px;
  }
  .cs_height_lg_23 {
    height: 23px;
  }
  .cs_height_lg_24 {
    height: 24px;
  }
  .cs_height_lg_25 {
    height: 25px;
  }
  .cs_height_lg_26 {
    height: 26px;
  }
  .cs_height_lg_27 {
    height: 27px;
  }
  .cs_height_lg_28 {
    height: 28px;
  }
  .cs_height_lg_29 {
    height: 29px;
  }
  .cs_height_lg_30 {
    height: 30px;
  }
  .cs_height_lg_31 {
    height: 31px;
  }
  .cs_height_lg_32 {
    height: 32px;
  }
  .cs_height_lg_33 {
    height: 33px;
  }
  .cs_height_lg_34 {
    height: 34px;
  }
  .cs_height_lg_35 {
    height: 35px;
  }
  .cs_height_lg_36 {
    height: 36px;
  }
  .cs_height_lg_37 {
    height: 37px;
  }
  .cs_height_lg_38 {
    height: 38px;
  }
  .cs_height_lg_39 {
    height: 39px;
  }
  .cs_height_lg_40 {
    height: 40px;
  }
  .cs_height_lg_41 {
    height: 41px;
  }
  .cs_height_lg_42 {
    height: 42px;
  }
  .cs_height_lg_43 {
    height: 43px;
  }
  .cs_height_lg_44 {
    height: 44px;
  }
  .cs_height_lg_45 {
    height: 45px;
  }
  .cs_height_lg_46 {
    height: 46px;
  }
  .cs_height_lg_47 {
    height: 47px;
  }
  .cs_height_lg_48 {
    height: 48px;
  }
  .cs_height_lg_49 {
    height: 49px;
  }
  .cs_height_lg_50 {
    height: 50px;
  }
  .cs_height_lg_51 {
    height: 51px;
  }
  .cs_height_lg_52 {
    height: 52px;
  }
  .cs_height_lg_53 {
    height: 53px;
  }
  .cs_height_lg_54 {
    height: 54px;
  }
  .cs_height_lg_55 {
    height: 55px;
  }
  .cs_height_lg_56 {
    height: 56px;
  }
  .cs_height_lg_57 {
    height: 57px;
  }
  .cs_height_lg_58 {
    height: 58px;
  }
  .cs_height_lg_59 {
    height: 59px;
  }
  .cs_height_lg_60 {
    height: 60px;
  }
  .cs_height_lg_61 {
    height: 61px;
  }
  .cs_height_lg_62 {
    height: 62px;
  }
  .cs_height_lg_63 {
    height: 63px;
  }
  .cs_height_lg_64 {
    height: 64px;
  }
  .cs_height_lg_65 {
    height: 65px;
  }
  .cs_height_lg_66 {
    height: 66px;
  }
  .cs_height_lg_67 {
    height: 67px;
  }
  .cs_height_lg_68 {
    height: 68px;
  }
  .cs_height_lg_69 {
    height: 69px;
  }
  .cs_height_lg_70 {
    height: 70px;
  }
  .cs_height_lg_71 {
    height: 71px;
  }
  .cs_height_lg_72 {
    height: 72px;
  }
  .cs_height_lg_73 {
    height: 73px;
  }
  .cs_height_lg_74 {
    height: 74px;
  }
  .cs_height_lg_75 {
    height: 75px;
  }
  .cs_height_lg_76 {
    height: 76px;
  }
  .cs_height_lg_77 {
    height: 77px;
  }
  .cs_height_lg_78 {
    height: 78px;
  }
  .cs_height_lg_79 {
    height: 79px;
  }
  .cs_height_lg_80 {
    height: 80px;
  }
  .cs_height_lg_81 {
    height: 81px;
  }
  .cs_height_lg_82 {
    height: 82px;
  }
  .cs_height_lg_83 {
    height: 83px;
  }
  .cs_height_lg_84 {
    height: 84px;
  }
  .cs_height_lg_85 {
    height: 85px;
  }
  .cs_height_lg_86 {
    height: 86px;
  }
  .cs_height_lg_87 {
    height: 87px;
  }
  .cs_height_lg_88 {
    height: 88px;
  }
  .cs_height_lg_89 {
    height: 89px;
  }
  .cs_height_lg_90 {
    height: 90px;
  }
  .cs_height_lg_91 {
    height: 91px;
  }
  .cs_height_lg_92 {
    height: 92px;
  }
  .cs_height_lg_93 {
    height: 93px;
  }
  .cs_height_lg_94 {
    height: 94px;
  }
  .cs_height_lg_95 {
    height: 95px;
  }
  .cs_height_lg_96 {
    height: 96px;
  }
  .cs_height_lg_97 {
    height: 97px;
  }
  .cs_height_lg_98 {
    height: 98px;
  }
  .cs_height_lg_99 {
    height: 99px;
  }
  .cs_height_lg_100 {
    height: 100px;
  }
  .cs_height_lg_101 {
    height: 101px;
  }
  .cs_height_lg_102 {
    height: 102px;
  }
  .cs_height_lg_103 {
    height: 103px;
  }
  .cs_height_lg_104 {
    height: 104px;
  }
  .cs_height_lg_105 {
    height: 105px;
  }
  .cs_height_lg_106 {
    height: 106px;
  }
  .cs_height_lg_107 {
    height: 107px;
  }
  .cs_height_lg_108 {
    height: 108px;
  }
  .cs_height_lg_109 {
    height: 109px;
  }
  .cs_height_lg_110 {
    height: 110px;
  }
  .cs_height_lg_111 {
    height: 111px;
  }
  .cs_height_lg_112 {
    height: 112px;
  }
  .cs_height_lg_113 {
    height: 113px;
  }
  .cs_height_lg_114 {
    height: 114px;
  }
  .cs_height_lg_115 {
    height: 115px;
  }
  .cs_height_lg_116 {
    height: 116px;
  }
  .cs_height_lg_117 {
    height: 117px;
  }
  .cs_height_lg_118 {
    height: 118px;
  }
  .cs_height_lg_119 {
    height: 119px;
  }
  .cs_height_lg_120 {
    height: 120px;
  }
  .cs_height_lg_121 {
    height: 121px;
  }
  .cs_height_lg_122 {
    height: 122px;
  }
  .cs_height_lg_123 {
    height: 123px;
  }
  .cs_height_lg_124 {
    height: 124px;
  }
  .cs_height_lg_125 {
    height: 125px;
  }
  .cs_height_lg_126 {
    height: 126px;
  }
  .cs_height_lg_127 {
    height: 127px;
  }
  .cs_height_lg_128 {
    height: 128px;
  }
  .cs_height_lg_129 {
    height: 129px;
  }
  .cs_height_lg_130 {
    height: 130px;
  }
  .cs_height_lg_131 {
    height: 131px;
  }
  .cs_height_lg_132 {
    height: 132px;
  }
  .cs_height_lg_133 {
    height: 133px;
  }
  .cs_height_lg_134 {
    height: 134px;
  }
  .cs_height_lg_135 {
    height: 135px;
  }
  .cs_height_lg_136 {
    height: 136px;
  }
  .cs_height_lg_137 {
    height: 137px;
  }
  .cs_height_lg_138 {
    height: 138px;
  }
  .cs_height_lg_139 {
    height: 139px;
  }
  .cs_height_lg_140 {
    height: 140px;
  }
  .cs_height_lg_141 {
    height: 141px;
  }
  .cs_height_lg_142 {
    height: 142px;
  }
  .cs_height_lg_143 {
    height: 143px;
  }
  .cs_height_lg_144 {
    height: 144px;
  }
  .cs_height_lg_145 {
    height: 145px;
  }
  .cs_height_lg_146 {
    height: 146px;
  }
  .cs_height_lg_147 {
    height: 147px;
  }
  .cs_height_lg_148 {
    height: 148px;
  }
  .cs_height_lg_149 {
    height: 149px;
  }
  .cs_height_lg_150 {
    height: 150px;
  }
  .cs_height_lg_151 {
    height: 151px;
  }
  .cs_height_lg_152 {
    height: 152px;
  }
  .cs_height_lg_153 {
    height: 153px;
  }
  .cs_height_lg_154 {
    height: 154px;
  }
  .cs_height_lg_155 {
    height: 155px;
  }
  .cs_height_lg_156 {
    height: 156px;
  }
  .cs_height_lg_157 {
    height: 157px;
  }
  .cs_height_lg_158 {
    height: 158px;
  }
  .cs_height_lg_159 {
    height: 159px;
  }
  .cs_height_lg_160 {
    height: 160px;
  }
}
/*--------------------------------------------------------------
  4. General
----------------------------------------------------------------*/
.cs_text_white {
  color: #ffffff;
}

.cs_text_primary {
  color: #0d0c17;
}

.cs_mp0 {
  list-style: none;
  margin: 0;
  padding: 0;
}

.cs_text_accent {
  color: var(--custom-color);
}

.cs_text_accent2 {
  color: #017534;
}

.cs_bg_accent {
  background-color: var(--custom-color);
}

.cs_bg_primary {
  background-color: #0d0c17;
}

.cs_bg_white {
  background-color: #ffffff;
}

.cs_gap_y_30 {
  gap: 30px 0;
}

.cs_gap_y_40 {
  gap: 40px 0;
}

.cs_gradient_bg_1 {
  background: linear-gradient(90deg, #f8f8ff 0.81%, rgba(248, 248, 255, 0) 100%);
}

.cs_gradient_bg_2 {
  background: linear-gradient(90deg, #f0fbf4 0.01%, rgba(240, 251, 244, 0) 99.99%);
}

.cs_bg_filled {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.cs_up_3_hover:hover {
  transform: translateY(-3px);
}

.cs_light {
  font-weight: 300;
}

.cs_normal {
  font-weight: 400;
}

.cs_medium {
  font-weight: 500;
}

.cs_semibold {
  font-weight: 600;
}

.cs_bold {
  font-weight: 700;
}

.cs_extra_bold {
  font-weight: 800;
}

.cs_black {
  font-weight: 900;
}

.cs_content {
  position: relative;
  background-color: #ffffff;
  z-index: 2;
  overflow-x: hidden;
}
@media (max-width: 991px) {
  .cs_content {
    margin-bottom: 0 !important;
  }
}

#cs_backtotop {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--custom-color);
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: fixed;
  right: 40px;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 4;
}
#cs_backtotop.active {
  visibility: visible;
  opacity: 1;
  bottom: 50px;
}

/*=============================
 Common component design
==============================*/
@media (max-width: 991px) {
  .cs_section_heading.cs_style_1 br {
    display: none;
  }
}
.cs_section_heading.cs_style_1 .cs_section_title {
  font-size: 50px;
  line-height: 1.3em;
  position: relative;
  z-index: 2;
}
@media (max-width: 1400px) {
  .cs_section_heading.cs_style_1 .cs_section_title {
    font-size: 42px;
  }
}
@media (max-width: 991px) {
  .cs_section_heading.cs_style_1 .cs_section_title {
    font-size: 32px;
  }
}
.cs_section_heading.cs_style_1 .cs_section_subtitle {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 1.6em;
}

.cs_section_heading.cs_style_2 .cs_section_subtitle {
  display: inline-block;
  position: relative;
  font-weight: 600;
  font-size: 18px;
}
.cs_section_heading.cs_style_2 .cs_section_subtitle::after, .cs_section_heading.cs_style_2 .cs_section_subtitle::before {
  content: "";
  width: 40px;
  height: 1px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cs_section_heading.cs_style_2 .cs_section_subtitle::before {
  background: linear-gradient(270deg, #d7c8c8 0%, rgba(215, 200, 200, 0) 100%);
  left: -55px;
}
.cs_section_heading.cs_style_2 .cs_section_subtitle::after {
  background: linear-gradient(90deg, #d7c8c8 0%, rgba(215, 200, 200, 0) 100%);
  right: -55px;
}

.cs_btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  padding: 12px 30px;
  border-radius: 7px;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  z-index: 1;
  outline: none;
  border: none;
}
@media (max-width: 390px) {
  .cs_btn {
    width: 100%;
    justify-content: center;
  }
  .cs_btn.cs_send {
    width: auto;
  }
}
.cs_btn.cs_dark_hover:hover {
  color: #ffffff;
}
.cs_btn.cs_dark_hover::after {
  content: "";
  background-color: #0d0c17;
}
.cs_btn::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition-property: transform;
  transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.cs_btn:hover {
  color: var(--custom-color);
}
.cs_btn:hover::after {
  transform: scaleX(1);
}

.cs_btn_group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
}
.cs_btn_group.cs_version_2 {
  justify-content: flex-start;
}
@media (max-width: 390px) {
  .cs_btn_group {
    flex-direction: column;
    gap: 15px;
  }
}

.cs_btn.cs_bg_accent::after {
  background: #ffffff;
}
.cs_btn.cs_bg_accent:hover {
  color: var(--custom-color);
}
.cs_btn.cs_bg_accent.cs_dark_hover:hover {
  color: #ffffff;
}
.cs_btn.cs_bg_accent.cs_dark_hover::after {
  background-color: #0d0c17;
}

.cs_btn.cs_bg_white {
  color: #0d0c17;
}
.cs_btn.cs_bg_white::after {
  background: var(--custom-color);
}
.cs_btn.cs_bg_white:hover {
  color: #ffffff;
}

.cs_btn.cs_bg_primary::after {
  background: #ffffff;
}
.cs_btn.cs_bg_primary:hover {
  color: #0d0c17;
}
.cs_btn.cs_bg_primary.cs_dark_hover:hover {
  color: #ffffff;
}
.cs_btn.cs_bg_primary.cs_dark_hover::after {
  background-color: var(--custom-color);
}

.cs_text_btn {
  position: relative;
  color: #0d0c17;
  font-weight: 500;
  line-height: 1.75em;
  transition: all 0.3s ease-in-out;
  z-index: 2;
}
.cs_text_btn svg {
  position: absolute;
  transition: inherit;
}
.cs_text_btn svg:first-child {
  transform: translate(0px, 25px);
  visibility: hidden;
  opacity: 0;
}
.cs_text_btn svg:last-child {
  transform: translate(7px, 9px);
}
.cs_text_btn:hover {
  color: var(--custom-color);
}
.cs_text_btn:hover svg:first-child {
  transform: translate(7px, 9px);
  visibility: visible;
  opacity: 1;
}
.cs_text_btn:hover svg:last-child {
  transform: translate(21px, -9px);
  visibility: hidden;
  opacity: 0;
}

.cs_business_feature .cs_featured_shape {
  position: absolute;
  top: 300px;
  right: 0;
}
@media (max-width: 1199px) {
  .cs_business_feature .cs_featured_shape {
    display: none;
  }
}

.cs_pr_45 {
  padding-right: 45px;
}
@media (max-width: 1400px) {
  .cs_pr_45 {
    padding-right: 0;
  }
}

.cs_feature_thumb_1 {
  width: calc(100% + 60px);
  padding-left: 60px;
}
@media (max-width: 1400px) {
  .cs_feature_thumb_1 {
    width: 100%;
    padding-left: 0px;
  }
}

.cs_list.cs_style_1 {
  display: grid;
  grid-gap: 25px 20px;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 767px) {
  .cs_list.cs_style_1 {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px 20px;
  }
}
.cs_list.cs_style_1 li {
  gap: 16px;
  padding: 0px 0 0px 45px;
  position: relative;
}
@media (max-width: 1400px) {
  .cs_list.cs_style_1 li {
    padding-left: 32px;
  }
}
.cs_list.cs_style_1 .cs_list_icon {
  position: absolute;
  left: 0;
  top: 0;
}
@media (max-width: 1400px) {
  .cs_list.cs_style_1 .cs_list_icon {
    top: 1px;
  }
}
.cs_list.cs_style_1 .cs_list_icon img {
  width: 32px;
}
@media (max-width: 1400px) {
  .cs_list.cs_style_1 .cs_list_icon img {
    width: 24px;
  }
}
.cs_list.cs_style_1 .cs_list_text {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.524em;
}
@media (max-width: 1400px) {
  .cs_list.cs_style_1 .cs_list_text {
    font-size: 20px;
  }
}

@media (max-width: 1199px) {
  .cs_section_shape_wrap_1 .cs_section_shape_1,
  .cs_section_shape_wrap_1 .cs_section_shape_2 {
    display: none;
  }
}
.cs_section_shape_wrap_1 .cs_section_shape_1 {
  top: 170px;
  right: 0;
}
.cs_section_shape_wrap_1 .cs_section_shape_2 {
  top: 28%;
  left: 12.4%;
}
.cs_section_shape_wrap_1 .container {
  position: relative;
  z-index: 2;
}

/* Start Review */
.cs_rating_percentage,
.cs_rating {
  overflow: hidden;
}
.cs_rating_percentage::before,
.cs_rating::before {
  content: "\f005\f005\f005\f005\f005";
  font-family: "Font Awesome 6 Free";
  position: absolute;
  top: 0;
  left: 0;
}

.cs_rating {
  flex: none;
  width: 126px;
  height: 25px;
  position: relative;
  color: var(--custom-color);
  font-size: 20px;
  letter-spacing: 3px;
}
.cs_rating::before {
  font-weight: 400;
}

.cs_rating_percentage {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.cs_rating_percentage::before {
  font-weight: 900;
}

/* End Review */
.cs_post_meta_icon {
  display: flex;
}

.cs_post_meta_wrap {
  gap: 20px;
}

.cs_post_meta_wrap,
.cs_post_meta {
  display: flex;
  align-items: center;
}

.cs_post_meta {
  gap: 5px;
}

@media (max-width: 1199px) {
  .cs_reverse_lg {
    flex-direction: column-reverse;
  }
}
@media (max-width: 991px) {
  .cs_reverse_md {
    flex-direction: column-reverse;
  }
}
/*--------------------------------------------------------------
5. Header
----------------------------------------------------------------*/
.cs_site_header {
  position: relative;
  z-index: 101;
}

.cs_site-branding {
  display: inline-block;
  max-width: 180px;
}

.cs_site_header {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0px;
  transition: all 0.4s ease;
}


.cs_site_header.cs_style_1 .cs_main_header_in,
.cs_site_header.cs_style_1 .cs_top_header_in {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 90px;
  position: relative;
}
.cs_site_header.cs_style_1 .cs_main_header_right {
  display: flex;
  align-items: center;
  height: 100%;
}




.cs_nav a {
  color:#000000;
  text-decoration: none;
  transition: all 0.3s ease;
}

.cs_nav a:hover {
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.cs_nav a:focus {
  text-decoration: none;
  color: rgb(255, 255, 255);
}



@media (max-width: 1199px) {
  .cs_site_header.cs_style_1 .cs_main_header_right {
    padding-right: 50px;
  }
}
@media (max-width: 1199px) {
  .cs_site_header.cs_style_1 .cs_btn.cs_bg_accent {
    display: block;
  }
}
.cs_site_header.cs_style_1 .cs_btn.cs_bg_accent {
  padding: 8px 20px;
}
.cs_site_header.cs_style_1 .cs_header_btns {
  display: flex;
  align-items: center;
  gap: 0 25px;
}

.cs_site_header.cs_style_1.cs_sticky_active {
  background-color: #0d0c17;
  /* box-shadow: 0 10px 10px -10px rgba(33, 43, 53, 0.1); */
}
.cs_site_header.cs_style_1.cs_sticky_active.cs_version_2 {
  background-color: #ffffff;
}
.cs_site_header.cs_style_1 .cs_action_box {
  display: flex;
  align-items: center;
}
.cs_site_header.cs_style_1 .cs_action_box .cs_action_value {
  margin-left: 15px;
}
.cs_site_header.cs_style_1 .cs_action_box > *:not(:last-child) {
  margin-right: 35px;
}

.cs_site_header_full_width .container {
  max-width: 100%;
  padding: 0 100px;
}

.cs_site_header_style1 {
  border-bottom: 1px solid #1a188d;
}
.cs_site_header_style1 .cs_main_header_right {
  display: flex;
  align-items: center;
}
.cs_site_header_style1 .cs_nav + .cs_header_toolbox.cs_center {
  margin-left: 35px;
}
.cs_site_header_style1 .cs_nav .cs_nav_list > li.current-menu-item > a:before {
  bottom: -4px;
  background-color: #ffffff;
}

@media screen and (max-width: 1199px) {
  .cs_main_header .container {
    max-width: 100%;
  }
  .cs_site_header.cs_style_1 .cs_nav {
    display: flex;
  }
}
.cs_nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cs_sticky_header {
  position: fixed !important;
  width: 100%;
  z-index: 999;
}

.cs_gescout_sticky {
  position: fixed !important;
  top: -110px;
  opacity: 0;
  transition: all 0.4s ease;
  background-color: #ffffff;
}

.cs_gescout_show {
  top: 0 !important;
  opacity: 1;
  box-shadow: 0px 1px 2px 0px rgba(2, 0, 181, 0.1);
}

.cs_site_branding {
  display: inline-block;
}
.cs_site_branding img {
  max-height: 45px;
}

@media screen and (min-width: 1200px) {
  .cs_main_header {
    position: relative;
  }
  .cs_main_header .container-fluid {
    padding-right: 40px;
    padding-left: 40px;
  }
  .cs_main_header_center,
  .cs_top_header_center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .cs_site_header.cs_style_1 .cs_main_header_center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    max-width: calc(100% - 300px);
  }
  .cs_site_header.cs_style_1 .cs_main_header_left {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cs_nav {
    display: flex;
    align-items: center;
    height: 100%;
    line-height: 1.6em;
  }
  .cs_nav .cs_nav_list {
    display: flex !important;
    flex-wrap: wrap;
    height: inherit;
  }
  .cs_nav .cs_nav_list > li {
    margin-right: 40px;
    height: inherit;
  }
  .cs_nav .cs_nav_list > li:last-child {
    margin-right: 0;
  }
  .cs_nav .cs_nav_list > li > a {
    padding: 10px 0;
    display: inline-flex;
    position: relative;
    height: inherit;
    align-items: center;
  }
  .cs_nav .cs_nav_list > li > a::before {
    content: "";
    position: absolute;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: var(--custom-color);
    left: 50%;
    margin-left: -4px;
    top: 60px;
    opacity: 0;
    transition: all 0.3s ease;
  }
  .cs_nav .cs_nav_list > li > a.active::before {
    opacity: 1;
  }
  .cs_nav .cs_nav_list > li > ul {
    left: 0;
    top: calc(100% + 15px);
  }
  .cs_nav .cs_nav_list > li > ul::before {
    content: "";
    position: absolute;
    height: 10px;
    width: 10px;
    background-color: #fff;
    transform: rotate(45deg);
    left: 20px;
    top: -5px;
    border-radius: 2px 0 0 0;
  }
  .cs_nav .cs_nav_list > li:hover > ul {
    top: 100%;
    opacity: 1;
    visibility: visible;
    transition: all 0.4s ease;
  }
  .cs_nav .cs_nav_list > li.menu-item-has-children > a {
    position: relative;
  }
  .cs_nav .cs_nav_list > li.menu-item-has-children > a::after {
    content: "";
    display: inline-block;
    height: 6px;
    width: 6px;
    border: 2px solid currentColor;
    transform: rotate(45deg);
    border-left: 0;
    border-top: 0;
    margin-left: 6px;
    position: relative;
    top: -1px;
    border-radius: 0px 0px 2px 0px;
  }
  .cs_nav .cs_nav_list li:not(.cs_mega_menu) {
    position: relative;
  }
  .cs_nav .cs_nav_list ul {
    width: 260px;
    background-color: #ffffff;
    position: absolute;
    box-shadow: 0px 1px 2px 0px rgba(2, 0, 181, 0.1);
    padding: 10px 0;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    display: block !important;
    border-radius: 5px;
    transition: all 0.1s ease;
  }
  .cs_nav .cs_nav_list ul li:hover ul {
    top: 0px;
  }
  .cs_nav .cs_nav_list ul li:hover > ul {
    opacity: 1;
    visibility: visible;
    transition: all 0.4s ease;
  }
  .cs_nav .cs_nav_list ul a {
    display: block;
    line-height: inherit;
    padding: 10px 20px;
    color: #0d0c17;
  }
  .cs_nav .cs_nav_list ul a:hover {
    color: var(--custom-color);
  }
  .cs_nav .cs_nav_list ul ul {
    top: 15px;
    left: 100%;
  }
  .cs_menu_toggle,
  .cs_munu_dropdown_toggle {
    display: none;
  }
  .cs_nav .cs_nav_list .cs_mega_menu {
    position: relative;
  }
  .cs_nav .cs_nav_list .cs_mega_wrapper {
    width: 475px !important;
    left: 0;
    display: flex !important;
    position: absolute;
    padding: 5px 15px 10px;
  }
  .cs_nav .cs_nav_list .cs_mega_wrapper a {
    padding: 7px 10px;
  }
  .cs_nav .cs_nav_list .cs_mega_wrapper > li {
    flex: 1;
    padding: 10px 0;
  }
  .cs_nav .cs_nav_list .cs_mega_wrapper > li > a {
    font-size: 18px;
    font-weight: 600;
    pointer-events: none;
  }
  .cs_nav .cs_nav_list .cs_mega_wrapper > li > a:hover {
    background-color: transparent;
  }
  .cs_nav .cs_nav_list .cs_mega_wrapper > li ul {
    position: initial;
    border: none;
    padding: 0;
    width: 100%;
    box-shadow: none;
    background-color: transparent;
  }
  .cs_nav .cs_nav_list .cs_mega_wrapper > li ul a {
    transition: all 0.4s ease;
  }
  .cs_nav .cs_nav_list .cs_mega_wrapper > li ul a:hover {
    letter-spacing: 1px;
  }
  .cs_nav .cs_nav_list .cs_mega_menu:hover .cs_mega_wrapper li ul {
    opacity: 1;
    visibility: visible;
  }
  .cs_nav .cs_nav_list > li ul:not(.cs_mega_wrapper) .menu-item-has-children > a {
    position: relative;
  }
}
@media screen and (max-width: 1400px) {
  .cs_nav .cs_nav_list .cs_mega_wrapper {
    width: 1116px !important;
  }
  .cs_site-branding {
    max-width: 190px;
  }
  .cs_site_header_full_width .container {
    max-width: 100%;
  }
}
@media screen and (max-width: 1199px) {
  .cs_nav .cs_nav_list .cs_mega_wrapper {
    width: 100% !important;
  }
  .cs_site_header_full_width .container {
    padding: 0 15px;
  }
  .cs_munu_dropdown_toggle {
    position: absolute;
    height: 40px;
    width: 100%;
    top: 0;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 23px 18px;
    cursor: pointer;
    z-index: 3;
  }
  .cs_munu_dropdown_toggle span {
    display: block;
    position: relative;
    height: 10px;
    width: 10px;
  }
  .cs_munu_dropdown_toggle span:before, .cs_munu_dropdown_toggle span:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 2px;
    width: 10px;
    background-color: currentColor;
    transition: all 0.3s ease;
  }
  .cs_munu_dropdown_toggle span:before {
    transform: translate(-50%, -50%) rotate(90deg);
  }
  .cs_munu_dropdown_toggle.active span:before {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  .menu-item-has-children .menu-item-has-children .cs_munu_dropdown_toggle {
    padding: 20px 18px;
  }
  .cs_site_branding {
    position: relative;
    z-index: 101;
  }
  .cs_version_2 .cs_nav .cs_nav_list {
    background-color: #ffffff;
    color: #0d0c17;
  }
  .cs_nav .cs_nav_list {
    position: fixed;
    width: 100vw;
    left: -100vw;
    background-color: #f7f7f7;
    color: #ffffff;
    padding: 10px 0;
    top: 0;
    z-index: 2;
    overflow: auto;
    min-height: 100vh;
    line-height: 1.6em;
    padding-top: 80px;
    transition: all 0.4s ease;
  }
  .cs_nav .cs_nav_list.cs_active {
    left: 0vw;
  }
  .cs_nav .cs_nav_list ul {
    padding-left: 15px;
    display: none;
  }
  .cs_nav .cs_nav_list a {
    display: block;
    padding: 12px 15px;
    line-height: 16px;
  }
  .cs_nav .cs_nav_list > li > a {
    font-size: 18px;
    line-height: 22px;
  }
  .cs_nav .menu-item-has-children {
    position: relative;
  }
  .cs_animo_links > li > a .cs_animo_text {
    text-shadow: 0 32px 0 currentColor;
  }
  .cs_animo_links > li > a:hover span {
    transform: translateY(-32px);
  }
  /*Mobile Menu Button*/
  .cs_menu_toggle {
    display: inline-block;
    width: 30px;
    height: 27px;
    cursor: pointer;
    position: absolute;
    top: 27px;
    right: 30px;
  }
  .cs_menu_toggle span,
  .cs_menu_toggle span:before,
  .cs_menu_toggle span:after {
    width: 100%;
    height: 2px;
    background-color: currentColor;
    display: block;
  }
  .cs_menu_toggle span {
    margin: 0 auto;
    position: relative;
    top: 12px;
    transition-duration: 0s;
    transition-delay: 0.2s;
  }
  .cs_menu_toggle span:before {
    content: "";
    position: absolute;
    margin-top: -9px;
    transition-property: margin, transform;
    transition-duration: 0.2s;
    transition-delay: 0.2s, 0s;
  }
  .cs_menu_toggle span:after {
    content: "";
    position: absolute;
    margin-top: 9px;
    transition-property: margin, transform;
    transition-duration: 0.2s;
    transition-delay: 0.2s, 0s;
  }
  .cs_site_header.cs_style_1 .cs_menu_toggle {
    top: 50%;
    right: 0px;
    margin-top: -13px;
  }
  .cs_toggle_active span {
    background-color: rgba(0, 0, 0, 0);
    transition-delay: 0.2s;
  }
  .cs_toggle_active span:before {
    margin-top: 0;
    transform: rotate(45deg);
    transition-delay: 0s, 0.2s;
  }
  .cs_toggle_active span:after {
    margin-top: 0;
    transform: rotate(-45deg);
    transition-delay: 0s, 0.2s;
  }
  .cs_nav .cs_nav_list a {
    position: relative;
  }
  .cs_site_header.cs_style_1 .cs_main_header_in {
    height: 80px;
  }
  .cs_site_header .current-menu-item > a:before {
    display: none;
  }
  .cs_site_header.cs_style_1 .cs_main_header_center .cs_site_branding {
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
  }
  .cs_site_header.cs_style_1 {
    top: 0;
  }
  .cs_has_main_nav {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .cs_site_header .container {
    max-width: 100%;
  }
  .cs_site_header.cs_style_1 .cs_action_box > *:not(:last-child) {
    margin-right: 25px;
  }
}
@media screen and (max-width: 575px) {
  .cs_site-branding {
    max-width: 150px;
  }
}
/*--------------------------------------------------------------
  6. Slider
----------------------------------------------------------------*/
/* .slick-list {
  overflow: visible;
}

@media (max-width: 1199px) {
  .cs_slider {
    padding: 0 15px;
  }
}

.slick-slider {
  margin: 0 -15px;
}

.slick-slide {
  margin-right: 15px;
  margin-left: 15px;
}

.slick-dots {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 0;
  margin-top: 40px;
}
.slick-dots button {
  padding: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.slick-dots li {
  overflow: hidden;
  border: none;
  width: 12px;
  height: 12px;
  border: 2px solid #0d0c17;
  border-radius: 2px;
  background-color: #ffffff;
  font-size: 0px;
  position: relative;
  padding: 0;
  cursor: pointer;
}
.slick-dots li::after {
  content: "";
  width: 4px;
  height: 4px;
  background-color: #0d0c17;
  border-radius: 1px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
.slick-dots .slick-active::after {
  opacity: 1;
} */

/*--------------------------------------------------------------
  7. CTA
----------------------------------------------------------------*/
.cs_cta.cs_style_1 {
  background-color: #0d0c17;
  padding: 90px 40px 100px;
  border-radius: 20px;
  overflow: hidden;
}
@media (max-width: 991px) {
  .cs_cta.cs_style_1 {
    padding: 75px 25px 80px;
  }
}
.cs_cta.cs_style_1 .cs_section_title {
  margin-bottom: 15px;
}
.cs_cta.cs_style_1 .cs_section_heading {
  margin-bottom: 35px;
}
.cs_cta.cs_style_1 .cs_section_subtitle {
  color: #a3a2a3;
  font-weight: 400;
}
@media (max-width: 1199px) {
  .cs_cta.cs_style_1 .cs_cta_shape1,
  .cs_cta.cs_style_1 .cs_cta_shape2 {
    display: none;
  }
}
.cs_cta.cs_style_1 .cs_cta_shape1 {
  position: absolute;
  top: 0;
  right: 0;
}
.cs_cta.cs_style_1 .cs_cta_shape2 {
  position: absolute;
  bottom: 0;
  left: 0;
}

/*--------------------------------------------------------------
8. Footer
----------------------------------------------------------------*/
.cs_site_footer {
  background-color: #0d0c17;
}
.cs_site_footer .cs_footer_widget_title {
  color: #ffffff;
  font-size: 21px;
  font-weight: 600;
  line-height: 1.523em;
  margin-bottom: 32px;
}
@media (max-width: 991px) {
  .cs_site_footer .cs_footer_widget_title {
    margin-bottom: 15px;
  }
}

.cs_main_footer {
  position: relative;
}

.cs_footer_logo {
  margin-bottom: 35px;
}
@media (max-width: 991px) {
  .cs_footer_logo {
    margin-bottom: 15px;
  }
}

.cs_social_btn.cs_style_1 {
  gap: 10px;
}
.cs_social_btn.cs_style_1 a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  color: #ffffff;
  border: 1px solid #ffffff;
}
.cs_social_btn.cs_style_1 a:hover {
  transform: translateY(-3px);
}

.cs_footer_widget + .cs_footer_widget {
  margin-top: 35px;
}

.cs_footer_widget_nav li {
  margin-bottom: 13px;
  transition: all 0.4s ease-in-out;
}
.cs_footer_widget_nav li:last-child {
  margin-bottom: 0;
}
.cs_footer_widget_nav a {
  color: #ffffff;
}
.cs_footer_widget_nav a:hover {
  color: var(--custom-color);
}

.cs_copyright_wrap {
  background-color: #0d0c17;
  position: relative;
}
.cs_copyright_wrap::after {
  content: "";
  width: 100%;
  height: 1px;
  opacity: 0.3;
  background: #a3a2a3;
  position: absolute;
  top: 0;
  left: 0;
}

.cs_copyright_text {
  padding: 20px 0;
  border-top: 1px solid rgba(163, 162, 163, 0.3);
}

.cs_newsletter .cs_btn.cs_bg_accent {
  display: inline-flex;
  align-items: center;
  gap: 7px;
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
  padding: 6px 20px;
}
.cs_newsletter .cs_btn.cs_bg_accent:hover {
  color: #ffffff;
}
.cs_newsletter .cs_btn.cs_bg_accent:hover::after {
  background-color: #017534;
}
@media (max-width: 991px) {
  .cs_newsletter {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .cs_newsletter {
    width: 100%;
  }
}

.cs_footer_shape1 {
  position: absolute;
  bottom: 140px;
  right: 130px;
}
@media (max-width: 1199px) {
  .cs_footer_shape1 {
    display: none;
  }
}

.cs_sticky_footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
@media (max-width: 991px) {
  .cs_sticky_footer {
    position: initial;
  }
}

/*--------------------------------------------------------------
9. Comments
----------------------------------------------------------------*/
.comments_title,
.comment_reply_title {
  font-size: 20px;
  color: #0d0c17;
  font-weight: 600;
  letter-spacing: 0.25px;
  margin-bottom: 25px;
}

.comments_area {
  padding-top: 56px;
  border-top: 1px solid #eaeaea;
}

.comment_body {
  position: relative;
  margin-left: 60px;
  margin-bottom: 25px;
  min-height: 50px;
}
@media (max-width: 400px) {
  .comment_body {
    margin-left: 0;
  }
}

.comments_area ol {
  list-style: none;
  padding-left: 0;
}

.comment_author .avatar {
  height: 50px;
  width: 50px;
  position: absolute;
  top: 0;
  left: -60px;
  border-radius: 6px;
}
@media (max-width: 400px) {
  .comment_author .avatar {
    position: initial;
  }
}

.comment_author {
  margin-bottom: -6px;
}

.comment_author .nm,
.comment_author .nm a {
  font-weight: 500;
  color: #0d0c17;
  font-size: 14px;
  display: block;
}

.comment_author .nm {
  padding-top: 4px;
}

.comment_author .nm a:hover,
.comment_author .nm:hover,
.comment_metadata a:hover {
  color: var(--custom-color);
}

.comment_metadata a {
  font-weight: 400;
  font-size: 13px;
}

.comment_content {
  font-size: 14px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.comment_content p {
  margin: 0;
  color: inherit;
  font-size: inherit;
}

.comment_reply_link {
  font-size: 14px;
  color: var(--custom-color);
  position: relative;
}

.comment_reply_link:hover,
.comment_reply_link:focus {
  color: var(--custom-color);
}

.comment_reply_link:before {
  content: "\f3e5";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  margin-right: 6px;
}

.comment_respond {
  display: block;
  width: 100%;
  margin-top: 56px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-top: 1px solid #eaeaea;
  padding-top: 57px;
}

.comment_form {
  display: flex;
  flex-wrap: wrap;
}

.comment_form_author,
.comment_form_email {
  width: 50%;
}
@media (max-width: 767px) {
  .comment_form_author,
  .comment_form_email {
    width: 100%;
  }
}

.comment_form_email {
  padding-left: 10px;
}
@media (max-width: 767px) {
  .comment_form_email {
    padding-left: 0;
  }
}

.comment_form_comment {
  width: 100%;
}

.comment_form_author {
  padding-right: 10px;
}
@media (max-width: 767px) {
  .comment_form_author {
    padding-right: 0;
  }
}

.comment_form_url {
  padding-left: 0px;
  width: 100%;
}

.form_submit {
  margin-bottom: 0;
}

.comment_form textarea,
.comment_form input {
  border: 1px solid #eaeaea;
  padding: 11px 15px;
  width: 100%;
  background-color: transparent;
  display: block;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  border-radius: 7px;
  resize: none;
}
.comment_form textarea:focus,
.comment_form input:focus {
  outline: none;
  border-color: var(--custom-color);
}

/*--------------------------------------------------------------
  10. Hero
----------------------------------------------------------------*/
.cs_hero.cs_style_1 {
  background-color: #0d0c17;
  padding-top: 195px;
}
.cs_hero.cs_style_1 .container {
  position: relative;
  z-index: 2;
}
@media (max-width: 991px) {
  .cs_hero.cs_style_1 br {
    display: none;
  }
}
@media (max-width: 575px) {
  .cs_hero.cs_style_1 {
    padding-top: 150px;
  }
}
.cs_hero.cs_style_1 .cs_hero_subtitle {
  margin-bottom: 45px;
  color: #220000;
}
.cs_hero.cs_style_1 .cs_hero_title {
  margin-bottom: 20px;
}
.cs_hero.cs_style_1 .cs_hero_shape1,
.cs_hero.cs_style_1 .cs_hero_shape2,
.cs_hero.cs_style_1 .cs_hero_shape3 {
  position: absolute;
}
.cs_hero.cs_style_1 .cs_hero_shape1 {
  width: 128px;
  height: 128px;
  border-radius: 100%;
  background-color: #34ff7e;
  filter: blur(175px);
  top: 0;
  left: 0;
  animation: heroShape1Anim 10s linear infinite;
}
.cs_hero.cs_style_1 .cs_hero_shape2 {
  top: 37%;
  left: 18%;
  animation: animo-x 9s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}
.cs_hero.cs_style_1 .cs_hero_shape3 {
  top: -220px;
  right: -143px;
  height: 740px;
  width: 740px;
  border: 50px solid #fbaaab;
  opacity: 0.03;
  border-radius: 50%;
}

.cs_hero_title {
  font-size: 68px;
  line-height: 1.4em;
}
@media (max-width: 1400px) {
  .cs_hero_title {
    font-size: 60px;
  }
}
@media (max-width: 991px) {
  .cs_hero_title {
    font-size: 48px;
  }
}
@media (max-width: 575px) {
  .cs_hero_title {
    font-size: 42px;
  }
}

.cs_hero.cs_style_2 {
  height: 100%;
  min-height: 800px;
  padding: 200px 0px 140px;
  display: flex;
  align-items: center;
  overflow: hidden;
}
@media (max-width: 991px) {
  .cs_hero.cs_style_2 {
    padding: 140px 0 80px;
  }
}
.cs_hero.cs_style_2 .cs_hero_text {
  max-width: 600px;
  position: relative;
  z-index: 1;
}
@media (max-width: 991px) {
  .cs_hero.cs_style_2 .cs_hero_text {
    min-width: 100%;
  }
}
.cs_hero.cs_style_2 .cs_hero_title {
  margin-bottom: 47px;
}
@media (max-width: 991px) {
  .cs_hero.cs_style_2 .cs_hero_title {
    margin-bottom: 20px;
  }
}
.cs_hero.cs_style_2 .cs_hero_title mark {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 0.6em;
  max-width: 145px;
  background-color: rgba(229, 72, 0, 0.1);
}
.cs_hero.cs_style_2 .cs_hero_subtitle {
  margin-bottom: 45px;
}
@media (max-width: 991px) {
  .cs_hero.cs_style_2 .cs_hero_subtitle {
    margin-bottom: 30px;
  }
}
.cs_hero.cs_style_2 .cs_hero_thumb {
  position: relative;
  z-index: 1;
}
.cs_hero.cs_style_2 .cs_hero_thumb.width_50vw {
  width: 50vw;
  min-width: 750px;
  border: 5px solid #0d0c17;
  border-radius: 20px;
  overflow: hidden;
}
@media (max-width: 991px) {
  .cs_hero.cs_style_2 .cs_hero_thumb.width_50vw {
    min-width: 100%;
  }
}
.cs_hero.cs_style_2 .cs_hero_thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cs_hero.cs_style_2 .cs_hero_shape1 {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}
.cs_hero.cs_style_2 .cs_hero_shape1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cs_hero.cs_style_2 .cs_hero_shape2 {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: animo-xyz 9s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}
.cs_hero.cs_style_2 .cs_hero_shape2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cs_hero.cs_style_2 .cs_hero_shape_bar {
  margin-bottom: 10px;
}
.cs_hero.cs_style_2 .cs_hero_shape_arrow {
  left: 32%;
  bottom: -3%;
  animation: arrow-rotate 10s linear infinite;
}
@media (min-width: 2000px) {
  .cs_hero.cs_style_2 .cs_hero_shape_arrow {
    bottom: 5%;
  }
}
@media (min-width: 2500px) {
  .cs_hero.cs_style_2 .cs_hero_shape_arrow {
    bottom: 10%;
  }
}
@media (max-width: 1399px) {
  .cs_hero.cs_style_2 .cs_hero_shape_arrow {
    left: 36%;
  }
}
@media (max-width: 1199px) {
  .cs_hero.cs_style_2 .cs_hero_shape_arrow {
    left: 42%;
  }
}
@media (max-width: 991px) {
  .cs_hero.cs_style_2 .cs_hero_shape_arrow {
    display: none;
  }
}

@keyframes animo-x {
  50% {
    transform: translateX(100px) rotate(90deg);
  }
}
@keyframes animo-xyz {
  50% {
    transform: translateX(50px) translateY(50px) rotateX(30deg);
  }
}
@keyframes heroShape1Anim {
  50% {
    filter: blur(130px);
  }
}
@keyframes arrow-rotate {
  0% {
    transform: translateX(0px) rotateZ(40deg);
  }
  50% {
    transform: translateX(-20px) rotateZ(-20deg);
  }
  100% {
    transform: translateX(0px) rotateZ(40deg);
  }
}
/*--------------------------------------------------------------
  11. IconBox
----------------------------------------------------------------*/
.cs_iconbox.cs_style_1 {
  padding: 45px 30px 40px;
  transition: all 0.4s ease-in-out;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px 10px rgba(216, 216, 216, 0.25);
  border-radius: 15px;
  position: relative;
  z-index: 1;
}
@media (max-width: 1400px) {
  .cs_iconbox.cs_style_1 {
    padding: 45px 25px 40px;
  }
}
.cs_iconbox.cs_style_1 .cs_iconbox_icon {
  height: 56px;
  margin-bottom: 25px;
}
.cs_iconbox.cs_style_1 .cs_iconbox_title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 15px;
}
@media (max-width: 1399px) {
  .cs_iconbox.cs_style_1 .cs_iconbox_title {
    font-size: 20px;
  }
}
.cs_iconbox.cs_style_1 .cs_iconbox_subtitle {
  margin-bottom: 25px;
}
.cs_iconbox.cs_style_1 .cs_text_btn {
  color: var(--custom-color);
  font-weight: 600;
}
.cs_iconbox.cs_style_1:hover {
  transform: scale(1.01) translateY(-7px);
}

.cs_iconbox.cs_style_2 .cs_iconbox_title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 7px;
}
@media (max-width: 1399px) {
  .cs_iconbox.cs_style_2 .cs_iconbox_title {
    font-size: 20px;
  }
}
.cs_iconbox.cs_style_2 .cs_number_box {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 600;
}
.cs_iconbox.cs_style_2 .cs_iconbox_subtitle {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
  12. Pricing
----------------------------------------------------------------*/
.cs_pricing_table.cs_style_1 {
  border-radius: 15px;
  background: #ffffff;
  box-shadow: 0px 0px 20px 5px rgba(216, 216, 216, 0.4);
  padding: 40px;
  transition: all 0.4s ease-in-out;
  position: relative;
  overflow: hidden;
  z-index: 2;
}
@media (max-width: 1199px) {
  .cs_pricing_table.cs_style_1 {
    padding: 40px 20px;
  }
}
.cs_pricing_table.cs_style_1 .cs_pricing_head {
  display: inline-flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 35px;
}
.cs_pricing_table.cs_style_1 .cs_pricing_badge {
  position: absolute;
  top: 35px;
  right: -40px;
  background-color: #e54800;
  color: #ffffff;
  width: 192px;
  padding: 5px 0;
  transform: rotate(45deg);
  font-weight: 600;
  text-align: center;
}
.cs_pricing_table.cs_style_1 .cs_pricing_head_title {
  font-size: 30px;
  line-height: 1.207em;
  margin-bottom: 4px;
  transition: all 0.4s ease-in-out;
}
.cs_pricing_table.cs_style_1 .cs_pricing_head_subtitle {
  transition: all 0.4s ease-in-out;
}
.cs_pricing_table.cs_style_1 .cs_pricing_seperator {
  width: 100%;
  height: 1px;
  opacity: 0.4;
  background: #a3a2a3;
  margin-bottom: 25px;
  transition: all 0.4s ease-in-out;
}
.cs_pricing_table.cs_style_1 .cs_price_value {
  font-size: 38px;
  line-height: 1.315em;
  transition: all 0.4s ease-in-out;
  margin-bottom: 20px;
}
.cs_pricing_table.cs_style_1 .cs_price_value small {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6em;
  color: #6b6b6b;
  transition: all 0.4s ease-in-out;
}
.cs_pricing_table.cs_style_1 .cs_pricing_feature_list {
  padding-bottom: 35px;
}
.cs_pricing_table.cs_style_1 .cs_pricing_feature_list li {
  margin-bottom: 17px;
  transition: all 0.4s ease-in-out;
}
.cs_pricing_table.cs_style_1 .cs_pricing_feature_list li:last-child {
  margin-bottom: 0;
}
.cs_pricing_table.cs_style_1 .cs_pricing_feature_list i {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  color: #ffffff;
  background-color: var(--custom-color);
  font-size: 10px;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  margin-right: 9px;
  transition: all 0.4s ease-in-out;
}
.cs_pricing_table.cs_style_1 .cs_pricing_icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 1199px) {
  .cs_pricing_table.cs_style_1 .cs_pricing_icon {
    width: 60px;
    height: 60px;
  }
}
.cs_pricing_table.cs_style_1 .cs_pricing_btn {
  display: block;
  color: #ffffff;
  text-align: center;
  font-weight: 600;
  padding: 12px 30px;
  border-radius: 10px;
  background-color: var(--custom-color);
  transition: all 0.4s ease-in-out;
}
.cs_pricing_table.cs_style_1:hover, .cs_pricing_table.cs_style_1.active {
  background-color: var(--custom-color);
}
.cs_pricing_table.cs_style_1:hover .cs_pricing_feature_list li,
.cs_pricing_table.cs_style_1:hover .cs_price_value,
.cs_pricing_table.cs_style_1:hover .cs_pricing_head_title,
.cs_pricing_table.cs_style_1:hover .cs_pricing_head_subtitle,
.cs_pricing_table.cs_style_1:hover small, .cs_pricing_table.cs_style_1.active .cs_pricing_feature_list li,
.cs_pricing_table.cs_style_1.active .cs_price_value,
.cs_pricing_table.cs_style_1.active .cs_pricing_head_title,
.cs_pricing_table.cs_style_1.active .cs_pricing_head_subtitle,
.cs_pricing_table.cs_style_1.active small {
  color: #ffffff;
}
.cs_pricing_table.cs_style_1:hover .cs_pricing_feature_list li i,
.cs_pricing_table.cs_style_1:hover .cs_pricing_btn,
.cs_pricing_table.cs_style_1:hover .cs_pricing_seperator,
.cs_pricing_table.cs_style_1:hover .cs_pricing_icon, .cs_pricing_table.cs_style_1.active .cs_pricing_feature_list li i,
.cs_pricing_table.cs_style_1.active .cs_pricing_btn,
.cs_pricing_table.cs_style_1.active .cs_pricing_seperator,
.cs_pricing_table.cs_style_1.active .cs_pricing_icon {
  background-color: #ffffff;
  color: #0d0c17;
}

/*---------------------------------------------------------------
Pricing controller 
----------------------------------------------------------------*/
.cs_tab {
  display: none;
}
.cs_tab.active {
  display: block;
}

.cs_pricing_control {
  list-style: none;
  font-size: 20px;
  line-height: 1.6em;
  margin-bottom: 60px;
}
.cs_pricing_control li {
  display: flex;
  align-items: center;
  position: relative;
}
.cs_pricing_control li:last-child a::before {
  left: initial;
  right: 100%;
}
.cs_pricing_control li.active .cs_switch::before {
  left: 2px;
}
.cs_pricing_control li.active > a {
  color: var(--custom-color);
}
.cs_pricing_control .cs_switch {
  display: inline-block;
  position: relative;
  height: 26px;
  width: 60px;
  background: rgb(229, 72, 0);
  border: 1px solid rgb(229, 72, 0);
  border-radius: 25px;
  margin: 0 25px;
}
.cs_pricing_control .cs_switch::before {
  content: "";
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 37px;
  transform: translateY(-50%);
  transition: all 0.4s ease;
}
.cs_pricing_control a {
  position: relative;
}
.cs_pricing_control a::before {
  content: "";
  height: 24px;
  width: 52px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  z-index: 1;
}

/*--------------------------------------------------------------
  13. Testimonial
----------------------------------------------------------------*/
.cs_testimonial.cs_style_1 {
  min-width: 330px;
  border-radius: 15px;
  background: #ffffff;
  padding: 50px;
  box-shadow: 0px 0px 20px 10px rgba(216, 216, 216, 0.25);
}
.cs_testimonial.cs_style_1 .cs_client_info {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 25px;
}
.cs_testimonial.cs_style_1 .cs_client_name {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cs_testimonial.cs_style_1 .cs_client_ratings i {
  color: var(--custom-color);
}
.cs_testimonial.cs_style_1 .cs_client_review {
  margin-bottom: 20px;
}
.cs_testimonial.cs_style_1 .cs_client_img {
  flex: none;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  overflow: hidden;
}
.cs_testimonial.cs_style_1 .cs_client_img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.cs_testimonial.cs_style_1 .cs_rating {
  transform: scale(0.9);
  margin-left: -7px;
}

/*--------------------------------------------------------------
  14. Partners
----------------------------------------------------------------*/
.cs_partners {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px 30px;
}
@media (max-width: 450px) {
  .cs_partners {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px 30px;
  }
}
.cs_partners .cs_partner img {
  filter: grayscale(100%);
  transition: all 0.4s ease;
}
.cs_partners .cs_partner img:hover {
  filter: grayscale(0%);
}

/*--------------------------------------------------------------
15. Accordian
----------------------------------------------------------------*/
.cs_accordian {
  margin-bottom: 25px;
  border-radius: 10px;
  border: 1px solid #a3a2a3;
  background: #ffffff;
}
.cs_accordian:last-child {
  margin-bottom: 0;
}
.cs_accordian .cs_accordian_toggle {
  position: absolute;
  top: 45px;
  left: calc(100% - 45px);
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 991px) {
  .cs_accordian .cs_accordian_toggle {
    top: 33px;
    left: initial;
    right: 15px;
  }
}
.cs_accordian .cs_accordian_toggle span {
  position: relative;
  display: inline-block;
  height: 2px;
  width: 14px;
  background-color: #0d0c17;
}
.cs_accordian .cs_accordian_toggle span::after {
  content: "";
  height: 2px;
  width: 14px;
  background-color: #0d0c17;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  transition: all 0.3s ease;
}
.cs_accordian .cs_accordian_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.524em;
  padding: 30px 50px 30px 30px;
  cursor: pointer;
  margin-bottom: 0;
  transition: all 0.3s ease;
  position: relative;
  z-index: 2;
}
@media (max-width: 991px) {
  .cs_accordian .cs_accordian_title {
    font-size: 18px;
    padding: 20px 40px 20px 20px;
  }
}
.cs_accordian .cs_accordian_title:hover {
  color: var(--custom-color);
}
.cs_accordian .cs_accordian_body {
  padding: 0px 30px 30px 30px;
  margin-top: -5px;
}
@media (max-width: 991px) {
  .cs_accordian .cs_accordian_body {
    padding: 0px 20px 20px 20px;
    margin-top: -2px;
  }
}
.cs_accordian.active .cs_accordian_title {
  padding-bottom: 15px;
}
.cs_accordian.active .cs_accordian_toggle span::after {
  visibility: hidden;
  opacity: 0;
}

.cs_faq_shape1 {
  width: 162px;
  height: 162px;
  background-color: #017534;
  filter: blur(175px);
  position: absolute;
  top: 4%;
  left: 10%;
}

.cs_faq_shape2 {
  width: 162px;
  height: 162px;
  background-color: #8cacff;
  filter: blur(175px);
  position: absolute;
  top: 45%;
  right: 10%;
}

/*--------------------------------------------------------------
  16. Post
----------------------------------------------------------------*/
.cs_post.cs_style_1 {
  border-radius: 15px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 5px rgba(216, 216, 216, 0.5);
  position: relative;
  overflow: hidden;
}
.cs_post.cs_style_1 .cs_post_title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 25px;
  transition: all 0.4s ease-in-out;
}
.cs_post.cs_style_1 .cs_post_title:hover {
  color: var(--custom-color);
}
.cs_post.cs_style_1 .cs_post_content_in {
  padding: 25px 30px 25px;
}
.cs_post.cs_style_1 .cs_post_content_in .cs_post_meta_wrap {
  margin-bottom: 7px;
}
.cs_post.cs_style_1 .cs_post_user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 30px;
  position: relative;
  background: rgba(163, 162, 163, 0.1);
}
.cs_post.cs_style_1 .cs_post_thumb {
  overflow: hidden;
  border-radius: 5px 5px 0 0;
  position: relative;
}
.cs_post.cs_style_1 .cs_post_thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cs_post.cs_style_1 .cs_post_thumb .cs_posted_by {
  width: 60px;
  height: 60px;
  background-color: var(--custom-color);
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.223em;
  position: absolute;
  left: 35px;
  top: 35px;
  text-align: center;
  padding: 10px;
}

/*--------------------------------------------------------------
  17. Contact
----------------------------------------------------------------*/
.cs_contact_thumb {
  width: 50vw;
  margin-left: calc(-50vw + 102.7%);
  position: relative;
  padding-right: 70px;
}
@media (max-width: 1399px) {
  .cs_contact_thumb {
    margin-left: calc(-50vw + 102.7%);
    padding-right: 20px;
  }
}
@media (max-width: 1199px) {
  .cs_contact_thumb {
    width: 100%;
    margin-left: 0;
    padding-right: 0px;
    margin-bottom: 40px;
  }
}
.cs_contact_thumb::after {
  content: "";
  width: 164px;
  height: 164px;
  background-color: #8cacff;
  filter: blur(175px);
  position: absolute;
  top: 0;
  left: 180px;
}

.cs_form_field {
  display: block;
  width: 100%;
  padding: 11px 20px;
  border-radius: 10px;
  outline: none;
  transition: all 0.3s ease;
  border: 1px solid #a3a2a3;
  resize: none;
}
.cs_form_field:focus {
  border-color: var(--custom-color);
}

/*--------------------------------------------------------------
18. Blog Details
----------------------------------------------------------------*/
.cs_sidebar_social_btn {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: -4px;
  margin-bottom: -5px;
}
.cs_sidebar_social_btn a:not(:last-child) {
  margin-right: 15px;
}

.cs_post_details.cs_style_1 .cs_post_info {
  gap: 30px;
  margin-bottom: 20px;
}
.cs_post_details.cs_style_1 .cs_post_meta_wrap {
  margin-bottom: 10px;
}
.cs_post_details.cs_style_1 p {
  font-size: 18px;
  line-height: 1.7em;
  margin-bottom: 25px;
}
@media (max-width: 991px) {
  .cs_post_details.cs_style_1 p {
    font-size: 16px;
    margin-bottom: 20px;
  }
}
.cs_post_details.cs_style_1 .cs_post_thumb {
  margin-bottom: 25px;
}
.cs_post_details.cs_style_1 .cs_post_thumb img {
  width: 100%;
}
.cs_post_details.cs_style_1 blockquote {
  background: #f5faff;
  margin: 0;
  padding: 30px 40px 27px;
  font-size: 24px;
  line-height: 1.6em;
  border-left: 5px solid var(--custom-color);
  border-radius: 7px;
  font-weight: 400;
  margin: 30px 0;
}
.cs_post_details.cs_style_1 blockquote small {
  display: block;
  line-height: inherit;
  margin-top: 4px;
  font-size: 16px;
}
.cs_post_details.cs_style_1 blockquote small span {
  color: var(--custom-color);
}
@media (max-width: 991px) {
  .cs_post_details.cs_style_1 blockquote {
    padding: 20px 20px 17px;
    font-size: 20px;
  }
}
.cs_post_details.cs_style_1 .cs_post_meta {
  gap: 10px;
}
.cs_post_details.cs_style_1 h2 {
  font-size: 36px;
  margin-bottom: 15px;
}
@media (max-width: 991px) {
  .cs_post_details.cs_style_1 h2 {
    font-size: 30px;
  }
}
.cs_post_details.cs_style_1 h2.cs_post_title {
  font-size: 50px;
  margin-bottom: 20px;
}
@media (max-width: 575px) {
  .cs_post_details.cs_style_1 h2.cs_post_title {
    font-size: 36px;
  }
}
.cs_post_details.cs_style_1 img {
  margin-bottom: 30px;
  border-radius: 7px;
  margin-top: 5px;
}
.cs_post_details.cs_style_1 .embed_responsive {
  margin-top: 25px;
  margin-bottom: 25px;
}

.cs_post_tage_list {
  display: flex;
  flex-wrap: wrap;
  font-weight: 500;
}
.cs_post_tage_list li {
  margin-right: 5px;
}
.cs_post_tage_list li:not(:last-child):after {
  content: ",";
}
.cs_post_tage_list li a:hover {
  color: var(--custom-color);
}

.cs_post_tages,
.cs_post_share {
  display: flex;
}
.cs_post_tages .cs_post_tage_title,
.cs_post_tages .cs_post_share_title,
.cs_post_share .cs_post_tage_title,
.cs_post_share .cs_post_share_title {
  margin: 0;
  font-size: 16px;
  margin-right: 10px;
  line-height: 1.6em;
}

.cs_post_share_btn_list {
  display: flex;
  flex-wrap: wrap;
}
.cs_post_share_btn_list a:not(:last-child) {
  margin-right: 12px;
}
.cs_post_share_btn_list a:hover {
  color: var(--custom-color);
}

/*--------------------------------------------------------------
19. Modal
----------------------------------------------------------------*/
.cs_modal {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
}
.cs_modal.active {
  opacity: 1;
  visibility: visible;
}

.cs_modal_container {
  width: 100%;
  max-width: 1230px;
  margin: auto;
  position: relative;
  z-index: 2;
  padding: 50px;
  border-radius: 10px;
  background-color: #ffffff;
}
@media (max-width: 400px) {
  .cs_modal_container {
    padding: 30px 15px;
  }
}

.cs_modal_in {
  padding: 60px 20px 30px;
}

.cs_close_overlay {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  cursor: zoom-out;
  background-color: rgba(13, 12, 23, 0.9);
  backdrop-filter: blur(5px);
}

.cs_close_modal {
  padding: 0;
  border: none;
  height: 35px;
  width: 35px;
  position: absolute;
  right: 20px;
  top: 30px;
  background-color: #ff2d55;
  color: #ffffff;
  font-size: 18px;
  border-radius: 5px;
  transition: all 0.4s ease;
}
.cs_close_modal:hover {
  background-color: #b5b5b5;
}

.cs_modal_btn {
  cursor: pointer;
}

.cs_modal.cs_size_sm .cs_modal_container {
  width: 100%;
  max-width: 450px;
  border-radius: 15px;
  padding: 0;
}

/*--------------------------------------------------------------
  20. Login
----------------------------------------------------------------*/
.cs_login_container {
  width: 100%;
  margin: 0 auto;
  padding: 42px;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px 5px rgba(181, 181, 181, 0.25);
}
.cs_login_container .cs_logo {
  margin-bottom: 35px;
}
.cs_login_container .cs_login_title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
}
.cs_login_container .cs_login_subtitle {
  margin-bottom: 35px;
}
.cs_login_container .cs_text_field {
  margin-bottom: 20px;
  position: relative;
}
.cs_login_container .cs_text_field img,
.cs_login_container .cs_text_field > span {
  position: absolute;
  left: 13px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
}
.cs_login_container .cs_text_field .cs_form_field {
  padding: 11px 20px 11px 38px;
}
.cs_login_container .cs_login_meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.cs_login_container .cs_text_btn {
  color: var(--custom-color);
  line-height: 1.75em;
}
.cs_login_container .cs_btn.cs_style_1 {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.cs_login_container .cs_or {
  line-height: 1.75em;
  margin-bottom: 23px;
}
.cs_login_container .cs_social_btn.cs_style_1 {
  margin-bottom: 22px;
}