.place{
    display: flex;
}

.place:hover{
    background: rgb(183, 183, 183);
}
.scrollbar {
    overflow-y: scroll;
    /* scrollbar-width: 200px; */
  }
  .scrollbar-primary::-webkit-scrollbar {
    width: 12px;
    background-color: #fffcfc;
  }

.placeContainer{
    overflow-y: scroll;
    height: 200px;
    position: absolute;    
    left: 5%;
    font-size: 14px;
    /* top: 66%; */
    background: white;
    border: 2px solid #d3d3d3;
    width: 300px;
    z-index: 1;
    color: rgb(49, 49, 49);
}


/* .scrollbar-deep-purple::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
} */
.placeContainerkundali{
    height: 200px;
    overflow-y: scroll;
    position: absolute;
    left: 5%;
    top: 64%;
    transform: translate(-10px, 0px);
    /* left: 5%; */
    /* top: 66%; */
    background: white;
    border: 2px solid #ffc400;
    width: 300px;
    z-index: 1;
}

.placeName{
    padding: 5px;
}

.StateName{
    padding: 5px;
}

.countryName{
    padding: 5px;
}