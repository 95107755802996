.home-background{
  background-color: #FBFBF8;
  width: 100%;
  justify-content: center;
  min-height: 100vh;
}


.main_container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  min-height: calc(100vh - 50px);
  background-color: #FBFBF8;
}

.discount-img {
  flex: 50%;
  max-width: 50%;
  align-self: center;
}

.form-container {
  flex: 30%;
  max-width: 30%;
  align-self: center;
  background-color: #FBFBF8;
  margin: 10px !important;
}
/* Responsive styles for screens less than 1300px */
@media (max-width: 900px) {
  .form-container {
      flex: 100%;
      max-width: 100%
  }
  .discount-img {
      flex: 100%;
      max-width: 100%  /* 50% width for the second and third divs */
  }
}


.discount-img img{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.login-form{
  position: relative;
  top: 10rem;
  width: 100%;
  height: 100vh;
}
.login-header{
  /* background-color: #716b54; */
  background-image: linear-gradient(to bottom left, #e14eca, #ba54f5, #e14eca);
  box-shadow: rgb(50 50 93 / 0%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px -1px 0px 0px, rgb(10 37 64 / 35%) 0px 0px 8px 0px inset;
  margin:-10px;
  padding: 10px;
  margin-bottom: 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.login-box{
  padding: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
}
.animated-section {
  margin-bottom: 50px;
}

.animated-images {
  padding: 100px 0;
  background-size: cover;
  background-position: center;
}

.overlay{
  position:absolute;
  top:0;
  right:0;
  left:0;
  bottom:0;
  /* background:#1a19175e; */
  
}
.btnclass{
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}
.submitbtn{
  margin-top: 0%;
  background-color: #ffc400;
  padding: 5px 20px;
  border: 2px solid #716b54;
  border-radius: 5px;
}  
.submitbtn1{
  /* margin-top: 10px; */
  margin-bottom: 10px;
  width:200px;
  display: flex;
  align-self: center;
  /* align-items: center; */
  justify-content:center;
  background-color: #1183ca;
  padding: 5px 20px;
  border: 2px solid #716b54;
  border-radius: 5px;
  color:white
}  
.login-form >.form-control{
  margin-top: 10px;
  margin-bottom: 10px;
}
.form-register >.form-control{
  margin-top: 10px;
  margin-bottom: 10px;
}
.login-form input{
  margin-bottom: 20px;
  color: #000;
}
.form-register input{
  color: #000;
}
.form-register .form-control{    
  color: #000 !important;
}
.login-form .form-control{    
  color: #000 !important;
}
@media screen and (max-width: 600px) {
  .login-form{
   top: 2rem;
  }
  .discount{
    display: none;
  }
}

@media screen and (width: 280px) {
  .login-form{
   top: 2rem;
  }
  .discount{
    display: none;
  }
}

@media screen and (width: 540px) {
  .login-form{
   top: 2rem;
  }
  .discount{
    display: none;
  }
}

@media screen and (min-width: 900px) {
  .discount-img img{
    max-height: calc(100vh - 50px);
  }
}