/*--------------------------------------------------------------
  13. Testimonial
----------------------------------------------------------------*/
.cs_testimonial.cs_style_1 {
  min-width: 330px;
  border-radius: 15px;
  background: $white;
  padding: 50px;
  box-shadow: 0px 0px 20px 10px rgba(216, 216, 216, 0.25);

  .cs_client_info {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 25px;
  }

  .cs_client_name {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 2px;
  }

  .cs_client_ratings i {
    color: $accent;
  }
  .cs_client_review {
    margin-bottom: 20px;
  }
  .cs_client_img {
    flex: none;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .cs_rating {
    transform: scale(0.9);
    margin-left: -7px;
  }
}
