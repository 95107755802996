/* @import url(../../styles/tailwind-pre-build.css); */
/* @tailwind base;
@tailwind components;
@tailwind utilities; */

body {
  overflow-x: hidden;
  /* background-color: unset !important; */
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important;
}

.fixed-plugin {
  width: 40px;
}

.sugbtn {
  background-color: #1e1e2f;
  color: #fff !important;
}

.table-responsive {
  overflow-x: auto !important;
  padding-bottom: 10px;
}

.ReactTable table {
  width: 100%;
}

.modal-title h4 {
  font-weight: 400;
  font-size: 0.812499975rem;
}

/* .formmodal{
    width: 200px;
    height: 300px;
    position: absolute;
    left: 50%;
    right: 50%;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #1d8cf8;
    background-color: #9d3131;
} */

.collapse {
  visibility: visible !important;
}

.age-changer {
  border: none;
  background-color: #3358f4;
  background-image: linear-gradient(to bottom left, #1d8cf8, #3358f4, #1d8cf8);
  border-radius: 15px;
  width: 30px;
  height: 25px;
  margin-top: 2.5px;
  text-align: center;
  justify-content: center;
  color: #fff;
  margin-right: 5px;
}

.form-control {
  color: #000 !important;
}

input {
  color: #000 !important;
}

.form-label {
  margin-top: 15px;
}

.longitude {
  margin-left: 5px;
}

.dob-input {
  min-width: 50px !important;
}

.reactformdiv .form-control {
  height: calc(1.5rem + 2px) !important;
}

.horo-card {
  padding: 10px 0px !important;
}

.horoscop-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  align-self: stretch;
}

.horo-inner-3 .nav-tabs {
  padding: 0.2rem 0.2rem;
}

.maha-dasha .nav-tabs {
  padding: 0.2rem 0.2rem;
}

.horo-inner-3 .nav-link {
  display: block;
  padding: 1px 5px !important;
}

/* Default styles */
.horoscop-main {
  display: flex;
  justify-content: space-between;
  /* Center the child divs */
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;
}

.horo-inner-1 {
  flex: 1 1 40%;
  /* 40% width for the first div */
  max-width: 100%;
  /* Maximum width of 100% */
  border: 2px solid #ffc400;
  margin-left: 10px;
  margin-right: 10px;
}

.horo-inner-2,
.horo-inner-3 {
  flex: 1 1 25%;
  /* 30% width for the second and third divs */
  max-width: 400px;
  /* Maximum width of 400px */
  border: 2px solid #ffc400;
  margin-left: 10px;
  margin-right: 10px;
}
.scrolllist::-webkit-scrollbar {
  display: none;
  scroll-behavior: smooth;
}

.tabs {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  margin-top: 5.5px;
}

.tab_btn {
  padding: 6px 10px;
  background: unset;
  color: black;
  background-color: #f5f8f8;
  border-radius: 5px;
  outline: none;
  border: none;
}

.tab_btn_focus {
  padding: 6px 10px;
  background: unset;
  background-color: #2a385c;
  color: rgb(234, 234, 234);
  border-radius: 5px;
  outline: none;
  border: none;
}

.exp {
  background: #696969;
  border-radius: 6px;
  color: #bebebe;
  text-align: center;
  font-weight: 600 !important;
  margin: 4px 1px;
}

.card-bg {
  background: #fdc45029 !important;
}

.expired-pop {
  width: 100%;
  margin: 0;
}

.btn-plan {
  position: absolute;
  bottom: 20px;
}

/* .card-list{
    color: #000;
    list-style-type: none;
    position: relative;
    padding-left: 20px; 
    list-style-image: url(../img/checked.png);
  } */

/* .card-list::marker{
    content: url(../img/checked.png); 
    width: 15px;
    height: 15px;
    font-weight: 900; 
    position: absolute;
    left: 0;
    top: 0;
    color: #000;
} */

/* accordian */

/* body {
	background-color: #6DC4F4;
}h1 { 
     color:white;
 
} */

/* Core styles/functionality */

.tabs input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.tab__content {
  max-height: 0;
  overflow: hidden;
  transition: all 0.35s;
}
.tabs input:checked ~ .tab__content {
  max-height: 100%;
}

/* Accordian styles */
.accordion {
  color: var(--theme);
  border: 2px solid;
  border-radius: 0.5rem;
  overflow: hidden;
}
.tab__label,
.tab__close {
  display: flex;
  color: white;
  background: var(--theme);
  cursor: pointer;
}
.tab__label {
  justify-content: space-between;
  padding: 1rem;
  font-size: medium !important;
}
.tab__label::after {
  content: "\276F";
  width: 1em;
  height: 1em;
  text-align: center;
  transform: rotate(90deg);
  transition: all 0.5s;
}
.tabs input:checked + .tab__label::after {
  transform: rotate(270deg);
}
.tab__content p {
  margin: 0;
  padding: 1rem;
  font-size: medium !important;
}
.tab__close {
  justify-content: flex-end;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
}
.accordion--radio {
  --theme: var(--secondary);
}

/* Arrow animation */
.tab input:not(:checked) + .tab__label:hover::after {
  animation: bounce 0.5s infinite;
}
@keyframes bounce {
  25% {
    transform: rotate(90deg) translate(0.25rem);
  }
  75% {
    transform: rotate(90deg) translate(-0.25rem);
  }
}

.square-box {
  height: 80px;
  width: 100%;
  flex: 1;
  border-radius: 5px;
  background: rgba(130, 130, 130, 0.2);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, rgba(130, 130, 130, 0.2)),
    color-stop(18%, rgba(130, 130, 130, 0.3)),
    color-stop(33%, rgba(130, 130, 130, 0.2))
  );
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  background-size: 800px 100px;
  animation: wave-squares 2s infinite ease-out;
}

/* pulse animation */
.pulse-line {
  height: 12px;
  /* margin-bottom:6px; */
  border-radius: 2px;
  background: rgba(130, 130, 130, 0.2);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, rgba(130, 130, 130, 0.2)),
    color-stop(18%, rgba(130, 130, 130, 0.3)),
    color-stop(33%, rgba(130, 130, 130, 0.2))
  );
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

/*scroller*/
/* .horoscope-box {

} */

.horoscope-box::-webkit-scrollbar {
  width: 7px;
  cursor: pointer;
  background: none;
}

/* Track */
.horoscope-box::-webkit-scrollbar-track {
  background: transparent;
  cursor: pointer;
}

/* Handle */
.horoscope-box::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}

/* Handle on hover */
.horoscope-box::-webkit-scrollbar-thumb:hover {
  background: transparent;
  cursor: pointer;
}



/* varshfal */
.varshfal .nav .nav-item button{
 padding: 5px 8px;
 font-weight: 600;
}


/* navbar footer */
.nav-link-footer{
  color: #ffffff;
  padding: 0.5rem;
  font-size: 0.7500000025rem;
  text-transform: uppercase;
  text-decoration: none;
}

.nav-link-footer:hover {
   color: #3358f4 !important;
}


@keyframes wave-lines {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes wave-squares {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@media (min-width: 768px) {
  .age-changer {
    display: none;
  }
}




/* Responsive styles for screens less than 1300px */
@media (max-width: 1200px) {
  .horo-inner-2,
  .horo-inner-3 {
    flex: 1 1 50%;
    margin-top: 20px;
  }

  .horo-inner-1 {
    flex: 1 1 100%;
    max-width: 100%;
    /* 50% width for the second and third divs */
  }
}

/* Responsive styles for screens less than 800px */
@media (max-width: 800px) {
  .horo-inner-1,
  .horo-inner-2,
  .horo-inner-3 {
    flex: 1 1 100%;
    /* Full width for all divs */
    max-width: 100%;
  }
}

.horo-inner-3 .nav-item button {
  font-size: 14px;
}

.mahadasha-div,
.antardasha-div,
.pratyanterdasha-div {
  margin-right: 3.4%;
}

.chart-div {
  min-width: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

.maha-dasha .nav-tabs > .nav-item > .nav-link {
  padding: 1px 13px !important;
}

.horoscop-main .form-control {
  height: 30px;
  padding: 0px 5px 0px 5px !important;
  border-radius: 3px;
  margin-bottom: 4px;
}

.check-div {
  display: flex;
  align-items: center;
}

input[type="checkbox"] {
  width: 15px;
  margin-right: 5px;
}

.card form label + .form-control {
  margin-bottom: 0px !important;
}

.horo-inner-2 table td,
th {
  padding: 0 5px !important;
  font-family: "Poppins", sans-serif;
  font-size: 9px;
  font-weight: 600;
}

.form-group {
  margin-bottom: 0px !important;
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0%);
  transform: translate(-12%, 0%);
  transform: translate(-3%, 7%);
}

.lkadasha-modal .modal.show .modal-dialog {
  -webkit-transform: translate(0, 0%);
  transform: translate(-12%, 0%);
}

/* .modal-dialog{ */
/* max-width: 600px !important; */
/* height: 400px; */
/* max-height: 400px; */
/* margin: 1.75rem auto; */
/* } */
/* .modal-content{
    min-width: 700px !important;
    max-height: 600px;
} */
.formmodal .modal-content {
  min-width: 300px !important;
  max-height: 200px;
}

.modal-content .modal-header {
  /* padding: 20px;
    border-bottom: 1px solid; */
  padding: 5px;
  /* border-bottom: 1px solid #000 !important; */
}

.modal-content .modal-header button {
  padding: 0.5rem;
}

.modal-content .modal-body + .modal-footer {
  padding: 10px 10px;
  display: flex;
  justify-content: end;
}

.modal-content .modal-footer button {
  padding: 5px 10px;
  background: #dc3545 !important;
}

.modal-content .modal-body {
  line-height: 1.9;
  /* max-width: 600px !important; */
  max-height: 600px;
  padding: 20px;
  overflow-y: scroll;
  text-align: justify;
}

.modal-body ul {
  padding-left: 15px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #ffffff !important;
  background-color: #247bf7 !important;
  border-color: #e3e3e3 #e3e3e3 #1e1e2f;
}

.BsBsV {
  background-color: #2579f7 !important;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
}

.BsBsV input {
  margin-bottom: 0 !important;
}

.sc-hLBbgP input {
  margin-bottom: 0 !important;
}

/* Kundli Chart*/

/* .main {
    height: 308px;
    width: 96%;
    margin: 0 auto;
    border: 3px solid #000;
    float: none;
    overflow: hidden;
}

.sub {
    height: 232px;
    width: 202px;
    border: 3px solid #000;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-left: 50px;
    margin-top: 34px;
}

.line1 {
    height: 45px;
    width: 444px;
    border-bottom: 3px solid #000;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    margin-top: -125px;
    margin-left: -52px;
}

.line2 {
    height: 53px;
    width: 591px;
    border-top: 3px solid #000;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    margin-left: -71px;
    margin-top: -24px;
}
p{
    margin: 0;
}
.p1{
    margin-top: -210px;
    margin-left: 70px;
}
.p2{
    margin-top: -44px;
    margin-left: 220px;
}
.p3{
    margin-left: 16px;
}
.p4{
    margin-top: -46px;
    margin-left: 147px;
}
.p5{
    margin-top: -49px;
    margin-left: 268px;
}
.p6{
    margin-top: 33px;
    margin-left: 63px;
}
.p7{
    margin-top: -47px;
    margin-left: 215px;
}
.p8{
    margin-top: 35px;
    margin-left: 18px;
}
.p9{
    margin-top: -50px;
    margin-left: 139px;
}
.p10{
    margin-top: -50px;
    margin-left: 262px;
}
.p11{
    margin-top: 17px;
    margin-left: 65px;
}
.p12{
    margin-top: -58px;
    margin-left: 210px;
} */

.box-table {
  width: 100%;
  height: 330px;
  overflow: scroll;
  border: 2px solid #d3d3d3;
  /* display: flex;
  flex-wrap: wrap; */
}

.box {
  width: 100%;
  height: 16.66rem;
  border: 2px solid #d3d3d3;
  display: flex;
  flex-wrap: wrap;
  background: linear-gradient(
      to top right,
      transparent calc(50% - 2px),
      #d3d3d3 calc(50% - 1px) calc(50% + 1px),
      transparent calc(50% + 1px)
    ),
    linear-gradient(
      to top left,
      transparent calc(50% - 2px),
      #d3d3d3 calc(50% - 1px) calc(50% + 1px),
      transparent calc(50% + 1px)
    );
  background-size: 50% 50%;
  counter-reset: num;
  overflow: hidden;
  resize: cover;
}

.box span {
  flex-grow: 1;
  /* flex-basis:50%;
    height:25%; */
  flex-basis: 42%;
  height: 18%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: #000;
  font-weight: 600;
}

.box span:nth-child(3),
.box span:nth-child(5),
.box span:nth-child(9),
.box span:nth-child(11) {
  flex-basis: 25%;
}

.box span:nth-child(2),
.box span:nth-child(6),
.box span:nth-child(8),
.box span:nth-child(12) {
  height: 12.5%;
}

/* .box span:before {
    content:counter(num);
    counter-increment:num;
  } */
.maha-dasha {
  width: 64%;
  /* margin-right: 20px; */
  /* background-color: #ffc400; */
}

.maha-dasha .nav-link {
  display: block;
  padding: 1px 15px;
  border: 1px solid #3358f4 !important;
  color: #3358f4 !important;
  font-weight: 500;
}

.maha-dasha .nav-tabs {
  border-bottom: 0.0625rem solid #e3e3e3;
  background: aliceblue;
  justify-content: space-between;
}

.maha-dasha .nav-link.active {
  background-color: #3358f4 !important;
  background-image: linear-gradient(
    to bottom left,
    #1d8cf8,
    #3358f4,
    #1d8cf8
  ) !important;
  color: #fff !important;
}

.nav-tabs .nav-link:focus {
  background-color: #3358f4 !important;
  background-image: linear-gradient(
    to bottom left,
    #1d8cf8,
    #3358f4,
    #1d8cf8
  ) !important;
  color: #fff !important;
}

.maha-dasha .nav-link:hover {
  background-color: #3358f4 !important;
  background-image: linear-gradient(to bottom left, #1d8cf8, #3358f4, #1d8cf8);
  color: #fff !important;
}

.mbtn5 {
  margin-bottom: 5px !important;
}

.maha-dasha table td {
  padding: 0 5px !important;
  font-size: smaller;
}

.subh-ashubh {
  width: 34%;
}

.subh-ashubh .card-header {
  padding: 6px 0px 0 !important;
}

.subh-ashubh table td {
  padding: 3px 5px !important;
  font-size: 10px;
}

.ten {
  padding-left: 40px;
  padding-top: 20px;
}

.nine {
  padding-right: 40px;
  padding-top: 20px;
}

.seven {
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 20px;
}

.six {
  padding-inline-start: 35px;
  padding-top: 20px;
}

.mahadasha2 table td {
  padding: 3px 5px !important;
  font-size: 3.4%;
}

/* .mahadasha2 table{
    min-width: 400px;
    max-width: 400px;
} */
.mahadasha tr td {
  font-weight: 600;
  color: #000;
}

.top {
  position: absolute;
  top: 0;
}

.middle {
  max-height: calc(100vh - 300px);
  overflow-y: scroll;
}

.bottom {
  margin-bottom: 0px;
  background: linear-gradient(
    135deg,
    hsl(357deg, 69%, 49%) 0%,
    hsl(42deg, 100%, 66%) 100%
  );
}

::-webkit-scrollbar {
  width: 1px;
  height: 10px;
  background: #3f363605;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 255, 1);
  border-radius: px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 50%);
  background: #eeb33f00;
}

.form-check-input {
  position: absolute;
  margin-top: 3px !important;
  margin-left: -1.25rem;
}

.generatebtn {
  width: 200px !important;
  background: #5e72e4 !important;
  color: #fff !important;
  font-size: 1rem;
}

.flex-search {
  display: flex;
  align-items: center;
}

.card-flex {
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}

.rechargeform {
  /* width: 50%;
    margin: 0 auto;
    text-align: center;
    padding: 10px 10px;
    margin-top: 50px; */
  width: 41%;
  margin: 0 auto;
  text-align: center;
  padding: 30px 30px;
  /* margin-top: 50px; */
  margin-bottom: 50px;
  /* border:5px solid #2774f6; */
  box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px,
    rgb(10 37 64 / 35%) 0px -2px 6px 0px inset;
  /* box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
}

.rechargeform .rechargebtn {
  width: 40%;
  margin: 0 auto;
  color: #fff !important;
  background: linear-gradient(0deg, #3358f4 0%, #1d8cf8 100%);
}

.rechargebtn:hover {
  background: red !important;
}

.creditbox {
  /* min-height: 200px; */
  min-width: 255px;
  max-width: 255px;
  background: aliceblue;
  text-align: center;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.credithead {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.cbody {
  padding: 50px 20px !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.creditbox:hover {
  color: #3358f4;
  /* animation: pulse 1s linear; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  border-radius: 20px;
}

.bg-gr-purple {
  background: #8e2de2;
  background: -webkit-linear-gradient(to right, #4a00e08f, #8e2de270);
  background: linear-gradient(to right, #4a00e08f, #8e2de270);
}

.bg-gr-blue {
  background: #0052d4;
  background: -webkit-linear-gradient(to left, #6fb1fc, #4364f7b8, #0052d48c);
  background: linear-gradient(to left, #6fb1fc, #4364f7b8, #0052d48c);
}

.bg-gr-green {
  background: #00b09b;
  background: -webkit-linear-gradient(to left, #96c93d6b, #00b09b73);
  background: linear-gradient(to left, #96c93d6b, #00b09b73);
}

.bg-gr-red {
  background: #ed213a;
  background: -webkit-linear-gradient(to right, #eb9f0ebd, #e11c0fd6);
  background: linear-gradient(to right, #eb9f0ebd, #e11c0fd6);
}

.cont {
  display: block;
}

.cont2 {
  display: none;
}

.noticard {
  overflow: hidden;
  white-space: nowrap;
  background-color: #1d8cf8;
  color: #fff;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  box-shadow: rgb(0 0 0 / 20%) 0px 12px 28px 0px,
    rgb(0 0 0 / 10%) 0px 2px 4px 0px,
    rgb(255 255 255 / 5%) 0px 0px 0px 1px inset;
  box-shadow: rgb(0 0 0 / 25%) 0px 25px 50px -12px;
}

.noticard > * {
  display: inline-block;
  position: relative;
  animation: 10s linear 0s infinite alternate move;
}

.noticard > *.min {
  min-width: 100%;
}

.noticard ul {
  list-style: none;
  margin-bottom: 0;
}

.noticard ul li {
  color: #e3242b !important;
}

@keyframes move {
  0%,
  25% {
    transform: translateX(0%);
    left: 0%;
  }

  75%,
  100% {
    transform: translateX(-100%);
    left: 100%;
  }
}

@keyframes pulse {
  0% {
    transform: scale3d(60 30 62);
  }

  100% {
    background: #dffddd;
  }
}

.add-suggestion {
  margin: 0 auto;
  padding: 50px 0px;
}

.navbar.navbar-absolute {
  position: fixed !important;
  z-index: 1050;
}

.cardheaderall {
  margin: 0;
  padding: 0 10px !important;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

.loaderdiv {
  /* width: 100%;
    height: 100vh;
    position: absolute;
    background: #dde2e5b8;
    z-index: 1;
    top: 0%;
    top: 50%;
    bottom: 0;
    right: 0%;
    transform: translate(0px, -50%); */
  width: 100%;
  height: 100%;
  background: #403d3d66;
  position: absolute;
  z-index: 15;
  top: 0%;
  top: 50%;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  right: 0;
}

.loaderdiv div {
  width: 100%;
}

.loaderdiv div svg {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(3%, -25%);
  transform: translate(3%, -25%);
}

.loaderdiv1 {
  text-align: center;
  width: 200px;
  position: absolute;
  z-index: 1;
  top: 0%;
  /* top: 50%; */
  bottom: 0;
  left: 50%;
  transform: translate(-30%, -0%);
  right: 0;
}

.loaderdiv2 {
  display: flex;
  justify-content: center;
  width: 200px;
  height: 200px;
  position: absolute;
  z-index: 1;
  top: 10%;
  bottom: 0;
  left: 45%;
  transform: translate(-30%, -0%);
  right: 0;
}

.loaderdiv1 svg {
  margin: 0 auto;
}

.three-dots-loading {
  justify-content: center;
}

.blink {
  animation: blink-animation 2s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.fbSshE {
  background-color: #2675f6 !important;
  color: #fff;
  font-size: 13px;
  min-height: 40px !important;
  font-weight: 600;
}

.detailsdiv {
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px,
    rgb(0 0 0 / 30%) 0px 1px 3px -1px;
}

.mahadasha-modal .modal-content {
  position: absolute;
}

.lkadasha-modal.modal-content {
  position: absolute;
}

.modal.lkadasha-modal.show .modal-dialog {
  -webkit-transform: translate(0%, 0%) !important;
  transform: translate(0%, 0%) !important;
}

.chakkardiv button {
  padding: 5px 10px;
  margin-bottom: 20px;
}

.chakkardiv input {
  height: 30px;
}

.chakartable thead {
  background: #2079eb;
  color: #fff;
}

.chakartable thead th {
  font-size: 15px;
}

.chakartable tbody tr {
  padding: 0;
}

.chakartable tbody tr td {
  padding: 3px 8px !important;
  font-size: 12px;
  cursor: pointer;
}

.lkaclose {
  height: 25px;
  padding: 0 5px;
  border: navajowhite;
  background: transparent;
  position: absolute;
  right: 27px;
  top: 20px !important;
  outline: 0;
  padding: 0 !important;
  margin: -1rem -1rem -1rem auto;
}

.navbar-expand-lg .navbar-nav {
  flex-direction: row;
  align-items: start !important;
}

.modal-content .modal-header button {
  position: absolute;
  right: 27px;
  top: 10px;
  outline: 0;
  padding: 0;
  margin: -1rem -1rem -1rem auto;
}

.formdivall {
  padding: 10px;
  box-shadow: 0 2px 4px 0 rgba(141, 138, 138, 0.688);
  margin: 0 auto;
  background: #fff;
  border-radius: 15px;
}

.white-content .card:not(.card-white) {
  background: #fff;
  box-shadow: none !important;
}

.selected {
  background-color: #32cd32;
}

.accordian-header div {
  margin-top: 0 !important;
}

.react-tabs__tab-panel div {
  /* margin-top: 10px !important; */
  justify-content: space-between;
}

.accordion-header {
  font-size: 15px;
}

.accordion-header button {
  display: flex;
  align-items: baseline;
  border: none;
  background: transparent;
}

.accordion-header button div {
  margin-top: 0 !important;
}

.viewans button {
  font-size: 12px;
  width: 100px;
  margin-left: 20px;
  background: blue;
  border: 1px solid gray;
  color: #fff;
  padding: 5px;
  text-align: center;
  font-weight: 600;
}

.collapsdiv {
  display: flex;
  align-items: baseline;
}

.collapsdiv h2 {
  font-size: 16px;
}

.collapsdiv a {
  margin-left: 20px;
  padding: 5px 10px;
}

/* .content .show{
    ma
} */

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.not-active {
  pointer-events: none;
  cursor: default;
}

.titlediv {
  display: flex;
  flex-direction: row;
}

.titlediv h2 {
  margin-bottom: 0;
  font-size: 16px;
  width: 70%;
}

.titlediv button {
  /* padding: 5px 10px
    font-size: 12px;
    margin-left: 20px; */
  border-radius: 20px;
  padding: 4px 10px;
  font-size: 12px;
  margin-left: 20px;
  background: #3358f4;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
  width: fit-content;
  height: fit-content;
}

.react-tabs__tab-panel--selected {
  min-height: 60vh;
}

.dashboardbtn {
  padding: 5px 8px !important;
  font-size: 12px !important;
  width: 100%;
}

.iconSize {
  width: 25%;
}

/* registration */

.registration-text {
  margin-top: 20px;
  font-weight: 400;
}

/* bold questions */
.titlediv h2 {
  font-weight: 700 !important;
}

/* slick slider button */

.slick-prev {
  z-index: 22 !important;
  background-color: #fff !important;
}

.slick-prev:before,
.slick-next:before {
  display: none;
  font-family: "slick";
  font-size: 35px !important;
  background-color: #fff !important;
  line-height: 1;
  opacity: 0.75;
  color: #344675 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 1200px) and (max-width: 1380px) {
  .box-table {
    width: 100%;
    height: 320px;
    border: 2px solid #d3d3d3;
    /* display: flex;
    flex-wrap: wrap; */
  }
  .box {
    width: 100%;
    height: 16.66rem;
    border: 2px solid #d3d3d3;
    display: flex;
    flex-wrap: wrap;
    background: linear-gradient(
        to top right,
        transparent calc(50% - 2px),
        #d3d3d3 calc(50% - 1px) calc(50% + 1px),
        transparent calc(50% + 1px)
      ),
      linear-gradient(
        to top left,
        transparent calc(50% - 2px),
        #d3d3d3 calc(50% - 1px) calc(50% + 1px),
        transparent calc(50% + 1px)
      );
    background-size: 50% 50%;
    counter-reset: num;
    overflow: hidden;
    resize: cover;
  }

  .box span {
    flex-grow: 1;
    /* flex-basis:40%;
        height:20%; */
    flex-basis: 42%;
    height: 18%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    color: #000;
    font-weight: 600;
  }

  .box span:nth-child(3),
  .box span:nth-child(5),
  .box span:nth-child(9),
  .box span:nth-child(11) {
    flex-basis: 20%;
  }

  .box span:nth-child(2),
  .box span:nth-child(6),
  .box span:nth-child(8),
  .box span:nth-child(12) {
    height: 10%;
  }

  .dashboardbtn {
    font-size: 10px !important;
  }

  .horo-inner-3 .nav-item button {
    font-size: 12px;
  }

  .iconSize {
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  .fixed-plugin {
    top: 3px !important;
  }
}

.stl3 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 750px) {
  .chart-div {
    min-width: 425px;
    max-width: 425px;
  }

  .row1 {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .col-md-61 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .my-21 {
    margin-bottom: 0.5rem !important;
  }

  .stl2 {
    width: 100%;
    height: 40px;
    display: grid;
    place-content: "center";
  }

  .stl3 {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .stl4 {
    position: absolute;
    top: -3.5rem;
    right: 20px;
    max-width: 78px;
    width: 60px;
    transform: translate(18px, 0px);
  }

  .col-md-31 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (min-width: 750px) {
  .stl {
    max-width: 50%;
  }

  .stl1 {
    width: 60px;
  }

  .mbtn51 {
    margin-bottom: 5px !important;
  }

  .d-flex1 {
    display: flex !important;
  }

  .maha-dasha1 {
    width: 64%;
    margin-right: 20px;
    /* background-color: #ffc400; */
  }

  .subh-ashubh1 {
    width: 34%;
  }

  .stl5 {
    position: absolute;
    top: 5px;
    right: 20px;
    max-width: 78px;
    width: 60px;
    transform: translate(18px, 0px);
  }
}

.col-md-31 {
  flex: 0 0 25%;
  max-width: 25%;
}

.stl5 {
  position: absolute;
  top: 5px;
  right: 20px;
  max-width: 78px;
  width: 60px;
  transform: translate(18px, 0px);
}

.stl4 {
  position: absolute;
  top: -3.5rem;
  right: 20px;
  max-width: 78px;
  width: 60px;
  transform: translate(18px, 0px);
}

.exist {
  margin-left: 0.5%;
  display: flex;
}

@media (max-width: 750px) {
  .exist {
    display: none;
  }

  .content-registration {
    margin-top: 130px;
  }

  .registration-text {
    font-weight: 400;
  }

  .card-bg {
    background: #fdc45029 !important;
    height: unset;
    position: relative;
  }

  .btn-plan {
    position: unset;
  }
}
