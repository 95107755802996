.reg-box{
  padding: 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px;
  border-radius: 10px;
  top: 20px;
}

.reg-background{
  background-color: #fff;
  width: 100%;
  justify-content: center;
  min-height: 100vh;
}


.reg_container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  min-height: calc(100vh - 50px);
  background-color: #fff;
}

.backlink{
  margin-top: 0px !important;
}

@media screen and (max-width: 600px) {
  .register_img{
    display: none;
  }
  .reg-box{
    box-shadow: none;
  }
}

@media screen and (width: 280px) {
  .register_img{
    display: none;
  }
  .reg-box{
    box-shadow: none;
  }
}

@media screen and (width: 540px) {
  .register_img{
    display: none;
  }
  .reg-box{
    box-shadow: none;
  }
}