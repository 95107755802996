/*--------------------------------------------------------------
  20. Login
----------------------------------------------------------------*/
.cs_login_container {
  width: 100%;
  margin: 0 auto;
  padding: 42px;
  border-radius: 15px;
  background-color: $white;
  box-shadow: 0px 0px 20px 5px rgba(181, 181, 181, 0.25);

  .cs_logo {
    margin-bottom: 35px;
  }

  .cs_login_title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .cs_login_subtitle {
    margin-bottom: 35px;
  }

  .cs_text_field {
    margin-bottom: 20px;
    position: relative;
    img,
    > span {
      position: absolute;
      left: 13px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
    }

    .cs_form_field {
      padding: 11px 20px 11px 38px;
    }
  }

  .cs_login_meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .cs_text_btn {
    color: $accent;
    line-height: 1.75em;
  }

  .cs_btn.cs_style_1 {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }

  .cs_or {
    line-height: 1.75em;
    margin-bottom: 23px;
  }

  .cs_social_btn.cs_style_1 {
    margin-bottom: 22px;
  }
}
